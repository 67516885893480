/* eslint-disable no-console */

import Leanplum from 'leanplum-sdk';

import { getLeanplumIsActive } from 'utils/MarketConfig';

import { LeanplumDefaultVariables } from './constant/Leanplum-default-variables';
import { LeanplumUserAttributes } from './type';

export const initializeLeanplum = (): void => {
  const leanplumAppID = process.env.REACT_APP_LEANPLUM_APP_ID as string;
  const leanplumKey = process.env.REACT_APP_LEANPLUM_KEY as string;

  if (!leanplumAppID || !leanplumKey) {
    console.log(`Leanplum is disabled`);
    return;
  }

  try {
    if (leanplumKey.startsWith('dev_')) {
      console.log('Leanplum - dev mode');
      Leanplum.setAppIdForDevelopmentMode(leanplumAppID, leanplumKey);
    } else {
      console.log('Leanplum - prod mode');
      Leanplum.setAppIdForProductionMode(leanplumAppID, leanplumKey);
    }

    Leanplum.setVariables(LeanplumDefaultVariables);

    Leanplum.start((success) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log(`@Leanplum Connection: ${success ? 'Success' : 'Failed'}`);
        console.log(`@Leanplum UserID`, Leanplum.getUserId());
        console.log('@Leanplum Variables', Leanplum.getVariables());
      }
    });
  } catch (error) {
    console.log('Failed to initialize Leanplum', JSON.stringify(error));
  }
};

export const syncLeanplum = (id: number, attributes: LeanplumUserAttributes): void => {
  const isLeanplumActive = getLeanplumIsActive();
  if (!isLeanplumActive) {
    console.log('Leanplum Sync is disabled');
    return;
  }
  try {
    Leanplum.setUserAttributes(`${id}`, attributes);
  } catch {
    console.log('Failed to Sync to Leanplum');
  }
};
