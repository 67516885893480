/* eslint-disable no-console */
/* eslint-disable consistent-return */

import { Action } from 'redux';
import moment from 'moment';
import { InvoiceListParam, InvoiceStatus, InvoiceSummary, swipeRxPt } from 'services';
import { recordException } from 'utils/Reporting/Sentry';
import { DepositListParam } from 'services/swipe-rx-pt/resources/deposit/interfaces/deposit-list-param.interface';
import { PaymentActionTypes } from './constants';
import { ThunkActionCreator } from '../../../types/thunk';
import { State } from '../../index';
import { v3Client } from '../../../utils/Client/v3';
import {
  BankAccount,
  Payments,
  Deposit,
  Meta,
  initialMeta,
  DPDBlockStatus,
  PaymentOptionData,
  PaymentInformation,
} from '.';

import ClientRequest from '../../../utils/Client';

/**
 * Action Types
 */

export enum PaymentsType {
  HISTORY = 'HISTORY',
  DUE_DATE = 'DUE_DATE',
  PAID = 'PAID',
}

export enum PaymentType {
  ALL_DUE = 'all',
  DUE_TODAY = 'today',
  DUE_WEEK = 'week',
  NOT_DUE = 'notYet',
  HISTORY = 'history',
  DEPOSITS = 'deposits',
  ALLOCATIONS = 'allocations',
}

interface PaymentPayload {
  invoices: Payments[];
  total_amount: number;
  meta: Meta;
}

// BANK ACCOUNT
export interface UpdateBankAccount extends Action {
  type: PaymentActionTypes.UPDATE_BANK_ACCOUNT;
  bankAccount: BankAccount;
}

export interface FailBankAccount extends Action {
  type: PaymentActionTypes.FAIL_BANK_ACCOUNT;
  error: string;
}

// DAYS PAST DUE
export interface SetDPDBlockStatusSuccess extends Action {
  type: PaymentActionTypes.SET_DPD_BLOCK_STATUS;
  payload: DPDBlockStatus;
}

export interface SetDPDBlockStatusFail extends Action {
  type: PaymentActionTypes.FETCH_DPD_BLOCK_STATUS_FAIL;
  payload: string;
}

// ALL DUE
export interface FetchAllDuePayments extends Action {
  type: PaymentActionTypes.FETCH_ALL_DUE_PAYMENTS;
  payload: PaymentPayload;
}

export interface PaginateAllDuePayments extends Action {
  type: PaymentActionTypes.PAGINATE_ALL_DUE_PAYMENTS;
  payload: PaymentPayload;
}

export interface ClearAllDuePayments extends Action {
  type: PaymentActionTypes.CLEAR_ALL_DUE_PAYMENTS;
}

export interface SetAllDueLoading extends Action {
  type: PaymentActionTypes.SET_ALL_DUE_LOADING;
  payload: { loading: boolean };
}

// DUE TODAY
export interface FetchDueTodayPayments extends Action {
  type: PaymentActionTypes.FETCH_DUE_TODAY_PAYMENTS;
  payload: PaymentPayload;
}

export interface PaginateDueTodayPayments extends Action {
  type: PaymentActionTypes.PAGINATE_DUE_TODAY_PAYMENTS;
  payload: PaymentPayload;
}

export interface ClearDueTodayPayments extends Action {
  type: PaymentActionTypes.CLEAR_DUE_TODAY_PAYMENTS;
}

export interface SetDueTodayLoading extends Action {
  type: PaymentActionTypes.SET_DUE_TODAY_LOADING;
  payload: { loading: boolean };
}

// DUE WITHIN WEEK

export interface FetchDueWithinWeekPayments extends Action {
  type: PaymentActionTypes.FETCH_DUE_WITHIN_WEEK_PAYMENTS;
  payload: PaymentPayload;
}

export interface PaginateWithinWeekPayments extends Action {
  type: PaymentActionTypes.PAGINATE_DUE_WITHIN_WEEK_PAYMENTS;
  payload: PaymentPayload;
}

export interface ClearDueWithinWeekPayments extends Action {
  type: PaymentActionTypes.CLEAR_DUE_WITHIN_WEEK_PAYMENTS;
}

export interface SetDueWithinWeekLoading extends Action {
  type: PaymentActionTypes.SET_DUE_WITHIN_WEEK_LOADING;
  payload: { loading: boolean };
}

// NOT YET DUE

export interface FetchNotYetDuePayments extends Action {
  type: PaymentActionTypes.FETCH_NOT_YET_DUE_PAYMENTS;
  payload: PaymentPayload;
}

export interface PaginateNotYetDue extends Action {
  type: PaymentActionTypes.PAGINATE_NOT_YET_DUE_PAYMENTS;
  payload: PaymentPayload;
}

export interface ClearNotYetDuePayments extends Action {
  type: PaymentActionTypes.CLEAR_NOT_YET_DUE_PAYMENTS;
}

export interface SetNotDueLoading extends Action {
  type: PaymentActionTypes.SET_NOT_DUE_LOADING;
  payload: { loading: boolean };
}

// HISTORY

export interface FetchPaymentHistory extends Action {
  type: PaymentActionTypes.FETCH_PAYMENT_HISTORY;
  payload: PaymentPayload;
}

export interface PaginatePaymentHistory extends Action {
  type: PaymentActionTypes.PAGINATE_PAYMENT_HISTORY;
  payload: PaymentPayload;
}

export interface ClearPaymentHistory extends Action {
  type: PaymentActionTypes.CLEAR_PAYMENT_HISTORY;
}

export interface SetPaymentHistoryLoading extends Action {
  type: PaymentActionTypes.SET_PAYMENT_HISTORY_LOADING;
  payload: { loading: boolean };
}

// DEPOSITS

export interface FetchDeposits extends Action {
  type: PaymentActionTypes.FETCH_DEPOSITS;
  payload: {
    total: 0;
    data: Deposit[];
    meta: Meta;
  };
}

export interface SetDepositsLoading extends Action {
  type: PaymentActionTypes.SET_DEPOSITS_LOADING;
  payload: { loading: boolean };
}

export interface ClearDeposits extends Action {
  type: PaymentActionTypes.CLEAR_DEPOSITS;
}

// ALLOCATIONS
export interface FetchInvoiceAllocations extends Action {
  type: PaymentActionTypes.FETCH_INVOICE_ALLOCATIONS;
  payload: Deposit[];
}

export interface SetDownloadInvoiceSummaryLoading extends Action {
  type: PaymentActionTypes.SET_DOWNLOAD_INVOICE_SUMMARY_LOADING;
  payload: { loading: boolean };
}

export interface SetDownloadInvoiceSummarySuccess extends Action {
  type: PaymentActionTypes.SET_DOWNLOAD_INVOICE_SUMMARY_SUCCESS;
  payload: { recipientEmail: string; isDownloadSuccess: boolean; showFailedMessage: boolean };
}

export interface SetDownloadInvoiceSummaryFailed extends Action {
  type: PaymentActionTypes.SET_DOWNLOAD_INVOICE_SUMMARY_FAILED;
}

// PAYMENT SELECTION

export interface SetPaymentSelection extends Action {
  type: PaymentActionTypes.PAYMENT_SELECTION_SET;
  payload: PaymentPayload;
}

// PAYMENT OPTION

export interface SetPaymentOptionData extends Action {
  type: PaymentActionTypes.PAYMENT_OPTION_SET;
  payload: Partial<PaymentOptionData>;
}

// PAYMENT INFORMATION

export interface SetPaymentInformation extends Action {
  type: PaymentActionTypes.PAYMENT_INFORMATION_SET;
  payload: Partial<PaymentInformation>;
}

export interface SetPaymentSummary extends Action {
  type: PaymentActionTypes.FETCH_INVOICE_SUMMARY;
  payload: InvoiceSummary;
}

export type Actions =
  | UpdateBankAccount
  | FailBankAccount
  | FetchAllDuePayments
  | PaginateAllDuePayments
  | ClearAllDuePayments
  | SetAllDueLoading
  | FetchDueTodayPayments
  | PaginateDueTodayPayments
  | ClearDueTodayPayments
  | SetDueTodayLoading
  | FetchDueWithinWeekPayments
  | PaginateWithinWeekPayments
  | ClearDueWithinWeekPayments
  | SetDueWithinWeekLoading
  | FetchNotYetDuePayments
  | PaginateNotYetDue
  | ClearNotYetDuePayments
  | SetNotDueLoading
  | FetchPaymentHistory
  | PaginatePaymentHistory
  | ClearPaymentHistory
  | SetPaymentHistoryLoading
  | FetchDeposits
  | SetDepositsLoading
  | ClearDeposits
  | FetchInvoiceAllocations
  | SetDPDBlockStatusSuccess
  | SetDPDBlockStatusFail
  | SetDownloadInvoiceSummaryLoading
  | SetDownloadInvoiceSummarySuccess
  | SetDownloadInvoiceSummaryFailed
  | SetPaymentSelection
  | SetPaymentOptionData
  | SetPaymentInformation
  | SetPaymentSummary;

/**
 * Actions
 */

export const fetchBankAccount: ThunkActionCreator<Actions, State> = () => async (dispatch) => {
  try {
    // Fetch bank account
    const organizationId = localStorage.getItem('organization_id');
    const bankAccount = await v3Client.get(`/organizations/${organizationId}/bank-account`);

    // Dispatch bank account
    dispatch({ type: PaymentActionTypes.UPDATE_BANK_ACCOUNT, bankAccount });
  } catch (error) {
    if (error instanceof Error) {
      recordException(error, 'fetchBankAccount');
      console.error(error.message);
      // Dispatch error
      dispatch({
        type: PaymentActionTypes.FAIL_BANK_ACCOUNT,
        error: error.message,
      });
    }
  }
};

export const fetchDeposits: ThunkActionCreator<Action, State> =
  (options: Record<string, unknown>, organizationId: number) => async (dispatch, getState) => {
    const newOptions: DepositListParam = {
      pharmacy_id: organizationId,
      page: 1,
      page_size: 6,
      ...options,
    };

    try {
      const {
        payment: {
          paid: { data },
        },
      } = getState();

      const { data: result, meta } = await swipeRxPt.deposit.list(newOptions);

      const { total_payments: total } = await swipeRxPt.deposit.total(organizationId);

      const deposit: Deposit[] = result.map(
        ({ id, deposited_at, deposited_amount, unallocated_amount, status, type }) => ({
          id,
          deposited_at,
          deposited_amount,
          unallocated_amount,
          status,
          type,
          allocations: {
            invoices: [],
            meta: { ...initialMeta },
          },
        }),
      );

      const newData = data.concat(deposit);
      const uniqueDeposits = new Set();
      const filteredDeposits = newData.filter((obj) => !uniqueDeposits.has(obj.id) && uniqueDeposits.add(obj.id));
      filteredDeposits.sort((a, b) => new Date(b.deposited_at).getTime() - new Date(a.deposited_at).getTime());

      const payload = {
        total,
        data: filteredDeposits,
        meta,
      };

      dispatch({
        type: PaymentActionTypes.FETCH_DEPOSITS,
        payload,
      });
    } catch (error) {
      recordException(error as Error, 'fetchDeposits', { options, organizationId });
      console.log(error);
    }
  };

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const clearPaid = () => ({
  type: PaymentActionTypes.CLEAR_DEPOSITS,
});

const setLoadStatus = (loading, type) => (dispatch) => {
  if (!loading) {
    setTimeout(() => {
      dispatch({
        type,
        payload: { loading },
      });
    }, 200);
  } else {
    dispatch({
      type,
      payload: { loading },
    });
  }
};

const setLoading = (type: PaymentType, loading: boolean) => (dispatch) => {
  switch (type) {
    case PaymentType.ALL_DUE: {
      dispatch(setLoadStatus(loading, PaymentActionTypes.SET_ALL_DUE_LOADING));
      break;
    }

    case PaymentType.DUE_TODAY: {
      dispatch(setLoadStatus(loading, PaymentActionTypes.SET_DUE_TODAY_LOADING));
      break;
    }

    case PaymentType.DUE_WEEK:
      dispatch(setLoadStatus(loading, PaymentActionTypes.SET_DUE_WITHIN_WEEK_LOADING));
      break;

    case PaymentType.NOT_DUE:
      dispatch(setLoadStatus(loading, PaymentActionTypes.SET_NOT_DUE_LOADING));
      break;

    case PaymentType.HISTORY:
      dispatch(setLoadStatus(loading, PaymentActionTypes.SET_PAYMENT_HISTORY_LOADING));
      break;

    case PaymentType.DEPOSITS:
      dispatch(setLoadStatus(loading, PaymentActionTypes.SET_DEPOSITS_LOADING));
      break;
  }
};

export const fetchInvoiceAllocations: ThunkActionCreator<Action, State> =
  (
    depositId,
    options: {
      pharmacy_id: number;
      page?: number;
      page_size?: number;
    },
    paginate,
  ) =>
  async (dispatch, getState): Promise<void> => {
    const newOptions = {
      page: 1,
      page_size: 99,
      ...options,
    };

    try {
      const {
        payment: {
          paid: { data },
        },
      } = getState();
      dispatch(setLoading(PaymentType.DEPOSITS, true));

      const { data: result, meta } = await swipeRxPt.invoiceAllocation.list({
        deposit_id: depositId,
        ...newOptions,
      });

      const invoices: Payments[] = result.map((allocation) => ({
        id: allocation.invoice_id,
        orderId: allocation.invoice.purchase_order_id,
        status: allocation.allocation_type as InvoiceStatus,
        po_number: allocation.invoice.purchaseOrder.po_number,
        invoice_number: allocation.invoice.invoice_number,
        distributor: allocation.invoice.purchaseOrder.distributor_id.toString(),
        invoiced_amount: allocation.invoice.invoiced_amount,
        paid_amount: allocation.invoice.paid_amount,
        total_amount: allocation.expected_outstanding,
        paid_at: allocation.created_at,
        ordered_at: moment(allocation.invoice.purchaseOrder.ordered_at).toDate(),
        invoiced_at: moment(allocation.invoice.invoiced_at).toDate(),
        due_date: moment(allocation.invoice.invoiced_at).add('30', 'days').toDate(),
        payment_status: allocation.invoice.payment_status,
        expected_amount: allocation.expected_amount,
      }));

      data.forEach((deposit) => {
        if (deposit.id === depositId) {
          deposit.allocations.invoices = paginate ? deposit.allocations.invoices.concat(invoices) : invoices;
          deposit.allocations.meta = meta;
        }

        return deposit;
      });

      dispatch({
        type: PaymentActionTypes.FETCH_INVOICE_ALLOCATIONS,
        payload: data,
      });
    } catch (error) {
      if (error instanceof Error) {
        recordException(error, 'fetchInvoiceAllocations', { depositId, options, paginate });
        console.log(error);
      }
    } finally {
      dispatch(setLoading(PaymentType.DEPOSITS, false));
    }
  };

export const fetchInvoiceSummary = () => async (dispatch, getState) => {
  const {
    auth: {
      coreUser: { organization_id: pharmacyId },
    },
  } = getState();

  const summary = await swipeRxPt.invoice.summary(pharmacyId);

  dispatch({
    type: PaymentActionTypes.FETCH_INVOICE_SUMMARY,
    payload: summary,
  });
};

const fetchPayments = async (options: Record<string, any>): Promise<any | undefined> => {
  try {
    const params: InvoiceListParam = {
      page: options?.page || 1,
      page_size: options?.page_size || 10,
      sort_by: options?.sort_by as string,
      order_by: options?.order_by,
      pharmacy_id: options?.pharmacy_id,
      only_paid: options?.only_paid ? 1 : null,
      include_status: options?.include_status,
      exclude_po_status: options?.exclude_po_status ? options.exclude_po_status.split(',') : null,
      exclude_status: options?.exclude_status,
      only_status: options?.only_status ? options.only_status.split(',') : null,
      due: options.due,
    };

    const { data, meta } = await swipeRxPt.invoice.list(params);

    const totalAmount = data.total_amount;
    const invoices: Payments[] = data.invoices.map((invoice) => {
      const {
        id,
        invoice_number,
        status,
        payment_status,
        invoiced_amount,
        paid_amount,
        paid_at,
        invoiced_at,
        purchase_order: { id: orderId, po_number, ordered_at, distributor },
      } = invoice;

      return {
        id,
        orderId,
        status,
        payment_status,
        po_number,
        invoice_number,
        distributor: distributor.name,
        invoiced_amount,
        paid_amount,
        total_amount: invoiced_amount - paid_amount,
        expected_amount: invoiced_amount - paid_amount,
        paid_at: moment(paid_at).toDate(),
        ordered_at: moment(ordered_at).toDate(),
        invoiced_at: moment(invoiced_at).toDate(),
        due_date: moment(invoiced_at).add(30, 'days').toDate(),
      };
    });

    return { invoices, total_amount: totalAmount, meta };
  } catch (error) {
    if (error instanceof Error) {
      recordException(error, 'fetchPayments', { options });
    }
    console.log(error);
  }
};

export const fetchPaymentsByType: ThunkActionCreator<Action, State> =
  (type: PaymentType, options: Record<string, unknown>) => async (dispatch) => {
    try {
      dispatch(setLoading(type, true));
      const payload = await fetchPayments(options);
      switch (type) {
        case PaymentType.ALL_DUE:
          dispatch({
            type: PaymentActionTypes.FETCH_ALL_DUE_PAYMENTS,
            payload,
          });
          break;
        case PaymentType.DUE_TODAY:
          dispatch({
            type: PaymentActionTypes.FETCH_DUE_TODAY_PAYMENTS,
            payload,
          });
          break;
        case PaymentType.DUE_WEEK:
          dispatch({
            type: PaymentActionTypes.FETCH_DUE_WITHIN_WEEK_PAYMENTS,
            payload,
          });
          break;
        case PaymentType.NOT_DUE:
          dispatch({
            type: PaymentActionTypes.FETCH_NOT_YET_DUE_PAYMENTS,
            payload,
          });
          break;
        case PaymentType.HISTORY:
          dispatch({
            type: PaymentActionTypes.FETCH_PAYMENT_HISTORY,
            payload,
          });
          break;
      }
    } catch (error) {
      if (error instanceof Error) {
        recordException(error, 'fetchPaymentsByType', { type, options });
      }
      console.log(error);
    } finally {
      dispatch(setLoading(type, false));
    }
  };

export const paginatePaymentsByType: ThunkActionCreator<Action, State> =
  (type: PaymentType, options: Record<string, unknown>) => async (dispatch) => {
    try {
      dispatch(setLoading(type, true));
      const payload = await fetchPayments(options);
      switch (type) {
        case 'all':
          dispatch({
            type: PaymentActionTypes.PAGINATE_ALL_DUE_PAYMENTS,
            payload,
          });
          break;
        case 'today':
          dispatch({
            type: PaymentActionTypes.PAGINATE_DUE_TODAY_PAYMENTS,
            payload,
          });
          break;
        case 'week':
          dispatch({
            type: PaymentActionTypes.PAGINATE_DUE_WITHIN_WEEK_PAYMENTS,
            payload,
          });
          break;
        case 'notYet':
          dispatch({
            type: PaymentActionTypes.PAGINATE_NOT_YET_DUE_PAYMENTS,
            payload,
          });
          break;
        case PaymentType.HISTORY:
          dispatch({
            type: PaymentActionTypes.PAGINATE_PAYMENT_HISTORY,
            payload,
          });
          break;
      }
    } catch (error) {
      recordException(error as Error, 'paginatePaymentsByType', { type, options });
      console.log(error);
    } finally {
      dispatch(setLoading(type, false));
    }
  };

export const clearPaymentsByType = (type: PaymentType) => (dispatch) => {
  switch (type) {
    case PaymentType.ALL_DUE:
      dispatch({
        type: PaymentActionTypes.CLEAR_ALL_DUE_PAYMENTS,
      });
      break;
    case PaymentType.DUE_TODAY:
      dispatch({
        type: PaymentActionTypes.CLEAR_DUE_TODAY_PAYMENTS,
      });
      break;
    case PaymentType.DUE_WEEK:
      dispatch({
        type: PaymentActionTypes.CLEAR_DUE_WITHIN_WEEK_PAYMENTS,
      });
      break;
    case PaymentType.NOT_DUE:
      dispatch({
        type: PaymentActionTypes.CLEAR_NOT_YET_DUE_PAYMENTS,
      });
      break;
    case PaymentType.HISTORY:
      dispatch({
        type: PaymentActionTypes.CLEAR_PAYMENT_HISTORY,
      });
  }
};

export const getPayments =
  (type: PaymentsType, options: Record<string, unknown>, due?: PaymentType, organizationId?: number) => (dispatch) => {
    switch (type) {
      case PaymentsType.PAID:
        dispatch(fetchDeposits(options, organizationId));
        break;
    }
  };

export const fetchDPDBlockStatus: ThunkActionCreator<Actions, State> = () => async (dispatch, getState) => {
  try {
    const {
      auth: { coreUser },
    } = getState();

    const pharmacyId = (coreUser as any).organization_id;

    const {
      data: { blocked, days_past_due, past_due_invoice_count },
    } = await ClientRequest.fetch(`/dpd/block-status/${pharmacyId}`);

    const dpdBlockStatus = {
      blocked,
      daysPastDue: days_past_due,
      pastDueInvoiceCount: past_due_invoice_count,
    };

    dispatch({
      type: PaymentActionTypes.SET_DPD_BLOCK_STATUS,
      payload: dpdBlockStatus,
    });
  } catch (error) {
    if (error instanceof Error) {
      recordException(error, 'fetchDPDBlockStatus');
      console.error(error.message);
      dispatch({
        type: PaymentActionTypes.FETCH_DPD_BLOCK_STATUS_FAIL,
        payload: error.message,
      });
    }
  }
};

export const startDownloadInvoiceSummary: ThunkActionCreator<Actions, State> = () => async (dispatch) => {
  try {
    dispatch(setLoadStatus(true, PaymentActionTypes.SET_DOWNLOAD_INVOICE_SUMMARY_LOADING));

    const { recipient_email } = await swipeRxPt.invoice.download();

    dispatch({
      type: PaymentActionTypes.SET_DOWNLOAD_INVOICE_SUMMARY_SUCCESS,
      payload: {
        recipientEmail: recipient_email,
        isDownloadSuccess: true,
        showFailedMessage: false,
      },
    });
  } catch (error) {
    recordException(error as Error, 'startDownloadInvoiceSummary');
    if (error.status === 400) {
      dispatch({ type: PaymentActionTypes.SET_DOWNLOAD_INVOICE_SUMMARY_FAILED });
    } else {
      console.error(error);
    }
  } finally {
    dispatch(setLoadStatus(false, PaymentActionTypes.SET_DOWNLOAD_INVOICE_SUMMARY_LOADING));
  }
};

export const resetDownloadInvoiceSummarySuccessValue = () => (dispatch) => {
  dispatch({
    type: PaymentActionTypes.SET_DOWNLOAD_INVOICE_SUMMARY_SUCCESS,
    payload: { recipientEmail: null, isDownloadSuccess: false, showFailedMessage: false },
  });
};

// PAYMENT SELECTION
export const setPaymentInformation = (payload: Partial<PaymentInformation>) => async (dispatch) => {
  dispatch({
    type: PaymentActionTypes.PAYMENT_INFORMATION_SET,
    payload,
  });
};

export const getAllDueInvoices =
  () =>
  (dispatch, getState): Payments[] => {
    const {
      payment: { allDuePayments },
    } = getState();

    return allDuePayments.data;
  };

export const fetchAllDueInvoices =
  () =>
  async (dispatch, getState): Promise<void> => {
    const {
      auth: { coreUser },
    } = getState();

    await dispatch(
      fetchPaymentsByType(PaymentType.ALL_DUE, {
        pharmacy_id: coreUser.organization_id,
        exclude_status: ['paid'].toString(),
        exclude_po_status: ['pending', 'processing'].toString(),
        page_size: 99999,
        page: 1,
        only_status: ['claimed', 'queued', 'failed', 'partial-return', 'full-return'].toString(),
        order_by: 'allocation_code',
        sort_by: 'asc',
      }),
    );
  };

export const deselectAllDueInvoices = () => (dispatch) => {
  dispatch({
    type: PaymentActionTypes.PAYMENT_SELECTION_SET,
    payload: {
      invoices: [],
      total_amount: 0,
      meta: {
        total_count: 0,
        page: 0,
        page_size: 0,
        page_count: 0,
      },
    },
  });
};

export const selectAllDueInvoices = () => async (dispatch, getState) => {
  try {
    const {
      depositRequest: { active },
    } = getState();

    // Restrict selection if any pending payment
    if (active) {
      dispatch(setPaymentInformation({ showDepositRequestWarning: true }));
      throw new Error('Finish the pending payment first');
    }

    dispatch(clearPaymentsByType(PaymentType.ALL_DUE));
    await dispatch(fetchAllDueInvoices());

    const {
      payment: { allDuePayments },
    } = getState();

    const allDuePaymentsData = allDuePayments.data;
    dispatch({
      type: PaymentActionTypes.PAYMENT_SELECTION_SET,
      payload: {
        invoices: allDuePaymentsData,
        total_amount: allDuePaymentsData.reduce((acc, item) => acc + item.total_amount, 0),
        meta: {
          total_count: allDuePaymentsData.length,
          page: 0,
          page_size: 0,
          page_count: 0,
        },
      },
    });
  } catch (e) {
    recordException(e as Error, 'selectAllDueInvoices');
    console.error(`@selectAllDueInvoices:${(e as Error).message}`);
  }
};

export const getPaymentInvoiceLastSelection =
  () =>
  (dispatch, getState): Payments => {
    const {
      payment: { paymentSelection },
    } = getState();

    // Find last selected and next selected
    return paymentSelection.data.slice(-1)[0];
  };

export const getPaymentInvoiceNextSelection =
  () =>
  (dispatch): Payments | null => {
    try {
      // Sort all invoices by invoiced_at
      const allInvoices = dispatch(getAllDueInvoices());

      // Get last selected invoice
      const lastSelection = dispatch(getPaymentInvoiceLastSelection());

      let nextSelectionIndex = 0;
      if (lastSelection) {
        nextSelectionIndex = allInvoices.findIndex((item) => item.id === lastSelection.id) + 1;
      }

      if (allInvoices.length < 1) return null;
      if (allInvoices.length <= nextSelectionIndex) return null;

      return allInvoices[nextSelectionIndex];
    } catch (e) {
      console.error(`@getPaymentInvoiceNextSelection:${(e as Error).message}`);
      recordException(e as Error, 'getPaymentInvoiceNextSelection');
      return null;
    }
  };

export const checkPaymentSelection: ThunkActionCreator<Actions, State> =
  (invoiceId: number) => async (dispatch, getState) => {
    try {
      const {
        payment: { paymentSelection },
        depositRequest: { active },
      } = getState();

      if (active) {
        dispatch(setPaymentInformation({ showDepositRequestWarning: true }));
        throw new Error('Finish pending payment first');
      }

      const nextSelection = dispatch(getPaymentInvoiceNextSelection());

      // Restrict selection
      if (!nextSelection || nextSelection.id !== invoiceId) throw new Error('Illegal selection');

      // New list of selected payment invoice
      let newPaymentSelectionInvoices = paymentSelection.data;
      if (nextSelection) {
        newPaymentSelectionInvoices = [...paymentSelection.data, nextSelection];
      }

      dispatch({
        type: PaymentActionTypes.PAYMENT_SELECTION_SET,
        payload: {
          invoices: newPaymentSelectionInvoices,
          total_amount: newPaymentSelectionInvoices.reduce((acc, item) => acc + item.total_amount, 0),
          meta: {
            total_count: newPaymentSelectionInvoices.length,
            page: 0,
            page_size: 0,
            page_count: 0,
          },
        },
      });
    } catch (e) {
      recordException(e as Error, 'checkPaymentSelection', { invoiceId });
      console.error(`@checkPaymentSelection:${(e as Error).message}`);
    }
  };

export const uncheckPaymentSelection: ThunkActionCreator<Actions, State> =
  (invoiceId: number) => async (dispatch, getState) => {
    try {
      const {
        payment: { paymentSelection },
        depositRequest: { active },
      } = getState();

      // Restrict selection if any pending payment
      if (active) {
        dispatch(setPaymentInformation({ showDepositRequestWarning: true }));
        throw new Error('Finish the pending payment first');
      }

      // Find last selected
      const lastSelection = paymentSelection.data.slice(-1)[0];
      if (!lastSelection) throw new Error('Empty list');

      // Restrict uncheck by FIFO
      if (lastSelection.id !== invoiceId) throw new Error('Illegal selection');

      // New list of selected payment invoice
      const newPaymentSelectionInvoices = paymentSelection.data.slice(0, -1);

      dispatch({
        type: PaymentActionTypes.PAYMENT_SELECTION_SET,
        payload: {
          invoices: newPaymentSelectionInvoices,
          total_amount: newPaymentSelectionInvoices.reduce((acc, item) => acc + item.total_amount, 0),
          meta: {
            total_count: newPaymentSelectionInvoices.length,
            page: 0,
            page_size: 0,
            page_count: 0,
          },
        },
      });
    } catch (error) {
      recordException(error as Error, 'uncheckPaymentSelection', { invoiceId });
      console.log(error);
    }
  };

export const setPaymentOptionData = (payload: Partial<PaymentOptionData>) => async (dispatch) => {
  dispatch({
    type: PaymentActionTypes.PAYMENT_OPTION_SET,
    payload,
  });
};
