import React from 'react';

import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { State as StoreState } from 'store';
import { CartContainer } from 'components/common';
import ViewCart from 'components/common/ViewCart';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { checkOverCreditLimit } from 'utils/helpers';
import { generateRandomId } from 'utils/Random';
import { routeKey } from 'utils/route.utils';

export const useCartNotification = (): any => {
  const { push } = useHistory();
  const {
    details: { remaining_credit: remainingLimit },
  } = useSelector((state: StoreState) => state.credit, shallowEqual);
  const { isLoading } = useSelector((state: StoreState) => state.global, shallowEqual);
  const { counterList, totalItem, totalAmount } = useSelector((state: StoreState) => state.counter, shallowEqual);

  const isOverLimit = checkOverCreditLimit(totalAmount, remainingLimit);

  const goToCart = (): void => {
    push(`${routeKey('prices')}`);
  };

  const viewCartId = generateRandomId('viewCartId');
  const shouldShowCart = counterList && Object.keys(counterList).length > 0;

  const loadingCart = (
    <CartContainer>
      <SkeletonTheme color="#ffffff">
        <Skeleton count={1} height />
      </SkeletonTheme>
    </CartContainer>
  );

  const viewableCart = (
    <CartContainer onClick={goToCart} style={{ zIndex: 6 }}>
      <ViewCart key={viewCartId} item={totalItem} amount={totalAmount} isOverLimit={isOverLimit} />
    </CartContainer>
  );

  const cart = !isLoading ? viewableCart : loadingCart;

  return {
    cart,
    shouldShowCart,
  };
};
