import { PtCoreUser } from 'store/reducers/Auth';
import { DistributorCartProduct } from 'store/reducers/Cart';

import { AnalyticsEvent } from '../interfaces';

import { makeAnalyticsRequest } from './make-analytics-request';
import { UndefinedAnalyticsProps } from './segment.types';
import { pageTracker } from '.';

export const identifyUser = (coreUser: PtCoreUser): void => {
  if (coreUser) {
    makeAnalyticsRequest(coreUser.user_id, AnalyticsEvent.Identify, {
      name: coreUser.user_name,
      email: coreUser.email,
      membership_status: coreUser.membership_status,
    });
  }
};

export const trackFailedCheckout = (errorMessage: UndefinedAnalyticsProps): void => {
  // much better to implement error codes
  window.analytics.track('pt_failed_checkout', { error_message: errorMessage });
};

export const trackDownloadInvoiceSummary = (): void => {
  makeAnalyticsRequest('pt_download_invoice_summary_clicked', AnalyticsEvent.Track, undefined);
};

export const trackContactCustomerCare = (pharmacy_id: number): void => {
  makeAnalyticsRequest('pt_contact_customer_care_clicked', AnalyticsEvent.Track, { pharmacy_id });
};

const trackCartItemRemoval = (event_name: string, pharmacy_id: number, products: DistributorCartProduct[]): void => {
  makeAnalyticsRequest(event_name, AnalyticsEvent.Track, {
    pharmacy_id,
    products,
    from_page: pageTracker.currentPage,
    total_removed_selling_price: products.map((product) => product.selling_price).reduce((a, b) => a + b, 0),
    total_removed_net_price: products.map((product) => product.net_price).reduce((a, b) => a + b, 0),
  });
};

export const trackCartItemRemove = (pharmacy_id: number, products: DistributorCartProduct[]): void => {
  trackCartItemRemoval('pt_product_remove_some', pharmacy_id, products);
};

export const trackCartAllItemRemove = (pharmacy_id: number, products: DistributorCartProduct[]): void => {
  trackCartItemRemoval('pt_product_remove_all', pharmacy_id, products);
};

export const trackCancelPayment = (pharmacy_id: number, user_id: string, amount: number): void => {
  makeAnalyticsRequest('pt_cancel_payment', AnalyticsEvent.Track, { pharmacy_id, user_id, amount });
};

export const trackCancelCheckout = (pharmacy_id: number): void => {
  makeAnalyticsRequest('pt_checkout_confirmation_cancel', AnalyticsEvent.Track, {
    pharmacy_id,
    from_page: pageTracker.currentPage,
  });
};

export const trackClearCart = (pharmacyData: UndefinedAnalyticsProps): void => {
  makeAnalyticsRequest('pt_delete_all_cart_items', AnalyticsEvent.Track, pharmacyData);
};

export const trackTierPricing = (tierPricingData: UndefinedAnalyticsProps): void => {
  makeAnalyticsRequest('pt_tier_pricing', AnalyticsEvent.Track, tierPricingData);
};

export const trackCreditLimitRequest = (pharmacy_id: number, current_limit: number, requested_limit: number): void => {
  makeAnalyticsRequest('pt_credit_limit_submit', AnalyticsEvent.Track, { pharmacy_id, current_limit, requested_limit });
};
