/* eslint-disable no-console */
import axios from 'axios';
import moment from 'moment';
import { Action } from 'redux';
import { IAllState } from 'store';
import { v3Client } from 'utils/Client/v3';

import { recordException } from 'utils/Reporting/Sentry';
import { getMarketId } from 'utils/MarketConfig';

import { getSwipeRxToken } from 'utils/Client/swipeRxToken.util';
import { syncLeanplum } from 'utils/Analytics/Leanplum';
import { initialiseReporting } from 'utils/Reporting';
import SwipeRxProcurement from '../../../utils/Warp/SwipeRxProcurement';
import * as constants from './constants';
import { ThunkActionCreator } from '../../../types/thunk';
import { PtCoreUser } from '.';

export interface SucceedAuthenticateAction extends Action {
  type: constants.SUCCEED_AUTHENTICATE;
  access_token?: string;
  activated?: string;
  agreed: boolean;
  tours: string[];
  coreUser: PtCoreUser;
  organization_name?: string;
  refresh_token?: string;
  permission?: string[];
}

export interface AgreeToTermsAction extends Action {
  type: constants.AGREE_TO_TERMS;
  agreed: boolean;
}

export interface FailAuthAction extends Action {
  type: constants.FAIL_AUTH;
  error: string;
}

export interface FailAuthCodedAction extends Action {
  type: constants.FAIL_AUTH_CODED;
  error: string;
  errorCode: string;
}

export type Actions = AgreeToTermsAction | FailAuthAction | FailAuthCodedAction | SucceedAuthenticateAction;

export const agreeToTerms: ThunkActionCreator<Actions, void> = () => async (dispatch) => {
  try {
    const result: any = await v3Client.post('me/agree-to-terms');

    // Dispatch the results
    dispatch({
      type: constants.AGREE_TO_TERMS,
      agreed: result.data.agreed_at,
    });
  } catch (error) {
    recordException(error, 'agreeToTerms');
    // eslint-disable-next-line no-console
    console.error(error.message);
    // Dispatch the error
    dispatch({ type: constants.FAIL_AUTH, error: error.message });
  }
};

export const authentication: ThunkActionCreator<Actions, IAllState> = (sessionToken: string) => async (dispatch) => {
  const marketId = getMarketId();

  try {
    // Clear the sessions
    SwipeRxProcurement.User.clearSession();

    const response: any = await v3Client.post('authentication/login', {
      code: sessionToken,
      grant_type: 'refresh_token',
    });

    if (response && response.status !== 201) {
      dispatch({ type: constants.FAIL_AUTH, error: response.error.message });
    }

    // Dispatch the user
    dispatch({
      type: constants.SUCCEED_AUTHENTICATE,
      user_id: response.data.id,
      coreUser: {
        user_name: response.data.username,
        email: response.data.email,
        organization_id: response.data.organization_id,
        organization_name: response.data.organization_name,
        organization_region: response.data.organization_region,
        organization_city: response.data.organization_city,
        organization_zipcode: response.data.organization_zipcode,
        is_homepage: response.data.is_homepage,
        external_id: response.data.external_id,
        user_id: response.data.external_id,
        membership_status: response.data.membership_status,
      },
      activated: response.data.activated_at,
      status: response.data.status,
      agreed: response.data.agreed_at,
      tours: [],
      permission: response.data.permission,
    });

    initialiseReporting();

    syncLeanplum(response.data.external_id, {
      email: response.data.email,
      pharmacy_id: response.data.organization_id,
      pharmacy_name: response.data.organization_name,
      pharmacy_dpd_blocked: response.data.organization_dpd_block_status,
      lived_at: Number(moment(response.data.lived_at as Date).format('YYYYMMDDHHmmss')),
      city: response.data.organization_city,
      territory: response.data.organization_area ? (response.data.organization_area as string[]).join(',') : '',
    });

    localStorage.setItem('access_token', response.data.access_token);
    localStorage.setItem('refresh_token', response.data.refresh_token);
    localStorage.setItem('organization_id', response.data.organization_id);
    localStorage.setItem(`organization_name_${marketId}`, response.data.organization_name);
    localStorage.setItem('coreUserId', response.data.external_id);
    return true;
  } catch (error) {
    // Dispatch the error
    // temporary solution to capture page of invalid user activation.
    dispatch({
      type: constants.FAIL_AUTH_CODED,
      error: 'User activation is invalid',
      errorCode: 'INVALID_USER_ACTIVATION',
    });

    return false;
  }
};

export const getTokenByRefreshToken = async (): Promise<string> => {
  const refreshToken = localStorage.getItem('refresh_token');
  if (!refreshToken) throw new Error('refresh token does not exist');

  const KEYCLOAK_URL = process.env.REACT_APP_KEYCLOAK_URL;
  const KEYCLOAK_REALM = process.env.REACT_APP_KEYCLOAK_REALM;
  const KEYCLOAK_CLIENT_ID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string;
  const KEYCLOAK_CLIENT_SECRET = process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET as string;

  const url = `${KEYCLOAK_URL}/realms/${KEYCLOAK_REALM}/protocol/openid-connect/token`;
  const params = new URLSearchParams();
  params.append('client_id', KEYCLOAK_CLIENT_ID);
  params.append('client_secret', KEYCLOAK_CLIENT_SECRET);
  params.append('grant_type', 'refresh_token');
  params.append('refresh_token', refreshToken);
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  const response = await axios.post(url, params, config);
  const { access_token, refresh_token } = response.data;
  if (!access_token) throw new Error('token is null');

  localStorage.setItem('access_token', access_token);
  localStorage.setItem('refresh_token', refresh_token);

  return access_token;
};

export const getNewToken = async (): Promise<string> => {
  const sessionToken = getSwipeRxToken();

  const response: any = await v3Client.post('authentication/login', {
    code: sessionToken,
    grant_type: 'refresh_token',
  });
  const { access_token, refresh_token } = response.data;

  localStorage.setItem('access_token', access_token);
  localStorage.setItem('refresh_token', refresh_token);

  return access_token;
};
