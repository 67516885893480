import { PharmacyOrderStatus, ActivityStatus, LoyaltyHistoryStatus, OrderStatus, OrderPrecursorStatus } from 'services';
import {
  LogisticDeliveryStatus,
  LogisticDeliveryStatusDetail,
} from 'services/swipe-rx-pt/resources/logistics-delivery/types';
import {
  LoyaltyMissionActivityTypeCode,
  LoyaltyMissionStatus,
  LoyaltyRewardRedeemDeliveryStatus,
  LoyaltyTierName,
} from 'store/reducers';
import { Lang } from './types';

export const cartModal = {
  viewCart: 'Lihat Keranjang',
  item: 'barang',
  items: 'barang',
};

const sectionCard = {
  seeAllAction: 'Lihat Semua',
  recentlyViewed: 'Terakhir Dilihat',
  close: 'Tutup',
};

const pharmacyTierInfo = {
  verifyAccount: 'Verifikasi Akun',
  learnMore: 'Pelajari',
  unpurchasableInfo: 'Produk ini hanya bisa dibeli akun Farmasi yang sudah terverifikasi',
  message: 'Halo, saya ingin mengupgrade akun saya, mohon bantuannya.',
  missingSipnap: 'Silakan kirimkan lisensi SIPNAP Anda untuk membeli produk prekursor',
};

const tabsProductDetail = {
  summary: 'Ringkasan',
  details: 'Rincian Produk',
  shipping: 'Pengiriman',
  recommendation: 'Rekomendasi',
};

const noProducts =
  'Barang yang anda cari tidak tersedia. Silahkan mencari produk lainnya atau bisa menghubungi Tim Customer Success untuk bantuan lebih lanjut.';
const productListingSearch = 'Cari produk {{productListingName}} disini…';

export const lang: Lang = {
  activationPage: {
    chatMessage: 'Hai, apakah saya bisa minta aktivasi?',
    contact: {
      clickButton: 'dengan cara menekan tombol di bawah',
      customerService: 'Layanan Pelanggan',
      please: 'Silahkan menghubungi mClinica',
    },
    request: 'Permintaan Aktivasi',
    requestAccess: 'Minta akses',
    title: 'SwipeRx Beta',
  },
  app: {
    agree: 'Saya setuju',
    seeAll: 'Lihat Semua',
    appName: 'SwipeRx Procurement v{{version}}',
    bottomNav: {
      cart: 'Keranjang',
      home: 'Beranda',
      orders: 'Aktivitas',
      payments: 'Pembayaran',
      products: 'Produk',
      account: 'Akun',
      favorites: 'Favorit',
    },
    credit: {
      pay: 'Bayar sekarang',
      total: 'Total limit kredit Anda adalah',
      used: 'Kredit limit yang telah digunakan {{amount}}',
    },
    dpd: {
      blockMessage:
        'Maaf, Anda tidak dapat menggunakan layanan SwipeRx selama tagihan masih terutang. Silahkan lunasi {{pastDueInvoiceCount}} tagihan terutang untuk menggunakan layanan belanja.',
    },
    suspended: {
      heading: 'Akun anda ditangguhkan untuk sementara',
    },
    orders: {
      used: 'Kredit Terpakai',
      total: 'Total Kredit',
      availableCredit: 'Kredit Tersisa',
      timeLeft: 'Waktu Tersisa',
      prekursorDocsRequired: 'Dokumen Prekursor Dibutuhkan',
    },
    decline: 'Saya tidak setuju',
    exit: 'Keluar',
    helpSupport: 'Bantuan dan dukungan',
    notFound: 'Tidak ditemukan',
    notification: {
      close: 'Tutup',
      retry: 'Coba kembali',
    },
    searchInput: 'Cari 3000+ produk di sini',
    tour: {
      start: 'Mulai tur',
      gotIt: 'Got It!',
      welcomeTitle: 'Selamat datang di aplikasi SwipeRx Belanja!',
      welcomeContent: 'Kita cek fitur terbaru di aplikasi SwipeRx Belanja, yuk!',
      naviTitle: 'Tab Nagivasi',
      naviContent: 'Perkenalkan Tab Navigasi yang baru',
      homeTitle: 'Tab Beranda',
      homeContent: 'Klik di sini untuk kembali ke beranda',
      productTitle: 'Produk',
      productContent: 'Di bagian ini Anda bisa melihat semua produk yang ditambahkan',
      basketTitle: 'Keranjang',
      basketContent: 'Klik di sini untuk melihat item yang telah Anda pilih',
      orderTitle: 'Tab Pesanan',
      orderContent: 'Klik di sini untuk melihat semua pesanan Anda',
      searchTitle: 'Pencarian',
      searchContent: 'Anda dapat mencari produk dengan nama merek atau molekul',
      accountTitle: 'Tab akun',
      accountTitleContent: 'Klik di sini untuk melihat detail akun',
      product: {
        productCardTitle: 'Detail produk',
        productCardContent:
          'Produk yang ada stok-nya kini tampil di paling atas. Geser ke paling bawah untuk menemukan produk yang sedang kehabisan stok.',
        productImageTitle: 'Gambar produk',
        productImageContent: 'Anda juga dapat memperbesar gambar produk dengan menekan gambar produk',
        productFavoriteTitle: 'Tombol Favorit',
        productFavoriteContent:
          'Sekarang, kamu bisa tandai produk mu sebagai favorit di aplikasi SwipeRx. Klik tombol hati untuk menandai produk tersebut sebagai favorit.',
        productAddContent: 'Tekan "Beli" untuk menambahkan produk ke keranjang Anda',
        productQuantityContent:
          'Sekarang lebih mudah untuk mengubah kuantitas produk Anda. Klik tombol tambah (+) untuk menambah produk atau tombol minus (-) untuk mengurangi produk',
        viewCartTitle: 'Cek keranjang',
        viewCartContent: 'Informasi keranjang anda',
        quickFilterTitle: 'Filter Cepat',
        quickFilterContent:
          'Pembelian Terakhir untuk melihat 3 produk terakhir yang pernah kamu beli sebelumnya, dan huruf untuk memfilter produk berdasarkan alphabet',
        appChatTitle: 'Layanan Pelanggan',
        appChat: 'Untuk pertanyaan, silakan hubungi Layanan Pelanggan Kami dengan mengetuk ikon ini',
        searchProductByLetterContent: 'Pilih huruf untuk filter produk',
      },
      tierDiscount: {
        title: 'Memperkenalkan Diskon Borongan',
        content: 'Sekarang, lebih mudah mendapatkan diskon jika membeli borongan.',
        expandBtn: 'Cukup ketuk tombol ini untuk melihat diskon borongan yang tersedia.',
        discountBtn: 'Untuk mengaktifkannya, ketuk opsi yang tersedia atau tambah barang sebanyak-banyaknya.',
      },
      updates: {
        title: 'Pembaruan terkini',
        content:
          'Kami baru saja memperkenalkan fitur baru kami untuk membuat pengalaman menggunakan Aplikasi menjadi jauh lebih baik',
        basketTitle: 'Keranjang',
        basketContent:
          'Anda sekarang memiliki keranjang baru! Klik pada tanda untuk menambah produk dan temukan lebih banyak lagi',
        accountTitle: 'Tab Akun',
        accountContent:
          'Kami telah menambahkan Tab Akun yang baru dimana Anda dapat menemukan detail mengenai Kredit, Pembayaran, dan lebih banyak lagi',
      },
      searchPage: {
        welcomeTitle: 'Halaman Pencarian',
        welcomeMessage:
          'Ini merupakan halaman search yang mana kamu akan mengetahui nama-nama produk apa saja yang akan kamu cari sebelum kamu submit!',
        mostPopularTitle: 'Paling Populer',
        mostPopularContent: 'Saat ini kamu dapat mengetahui juga produk paling populer yang ada di aplikasi SwipeRx!',
        noResult: {
          welcomeTitle: 'Merekomendasikan Produk Baru (1)',
          welcomeMessage:
            'Kamu juga dapat merekomendasikan produk baru apabila kamu tidak dapat menemukan produk yang ingin kamu beli!',
          suggestionTitle: 'Tombol Rekomendasi',
          suggestionContent: 'Klik tombol berikut ini untuk merekomendasikan produk yang ingin kamu inginkan, ya!',
        },
        packagingTitle: 'Temukan jenis kemasan untuk produk yang Anda cari',
        packagingContent: 'Dapatkan informasinya di bagian bawah nama produk',
      },
      wishlistLink: {
        title: 'Menu Wishlist',
        content: 'Ini adalah halaman wishlist, semua produk yang kamu tandai akan ada disini!',
      },
      wishlistPage: {
        welcomeTitle: 'Halaman Wishlist',
        welcomeMessage:
          'Berikut ini adalah halaman wishlist. Disini tersedia beberapa tab yang isinya merupakan produk yang telah kamu tandai sebagai favorit, kabari saya, hingga produk yang kamu rekomendasikan untuk ada di SwipeRx Belanja',
        favoritesTabTitle: 'Tab Favorit',
        favoritesTabContent:
          'Berikut adalah tab favorit. Pada tab favorit, kamu dapat menemukan produk yang kamu sudah tandai sebagai favorit loh!',
        remindersTabTitle: 'Tab Kabari Saya',
        remindersTabContent:
          'Ini adalah tab yang nantinya berisikan produk yang kalian sudah minta kabari melalui tombol “Kabari Saya',
        comingSoonTabTitle: 'Tab Segera Hadir',
        comingSoonTabContent:
          'Dan berikut ini adalah halaman untuk produk yang telah kamu rekomendasikan ke aplikasi SwipeRx, lho! Semua produk yang telah kamu rekomendasikan masuk disini!',
        askCsTitle: 'In-App Chat',
        askCsContent: 'Untuk pertanyaan lebih lanjut, kamu bisa hubungi langsung Customer Success kita ya!',
      },
      favoritesPage: {
        removeFavoriteTitle: 'Tombol Favorit',
        removeFavoriteContent:
          'Setelah diklik, kamu tentunya juga bisa hapus produk tersebut dari favorit dengan menekan tombol favorit tersebut kembali.',
      },
      remindersPage: {
        bellIconTitle: 'Klik icon lonceng untuk hapus produk dari list “Kabari Saya”',
        bellIconContent: 'Atur list produk sesuai kebutuhan Anda',
      },
      productDetailPage: {
        linkToPageTitle: 'Klik nama produk yang ada di Halaman utama untuk mengakses Halaman Produk',
        linkToPageContent:
          'Pada halaman utama, klik nama produk untuk mengakses deskripsi lengkap dari produk yang ingin Anda pesan',
        welcomeTitle: 'Halaman Produk',
        welcomeMessage: 'Selamat datang ke halaman produk!',
        promotionTitle: 'Dapatkan informasi tentang diskon produk jika produk memiliki promo',
        promotionContent: 'Temukan diskon sesuai jumlah minimum pembelian sesuai kebutuhan Anda',
        descriptionTitle: 'Temukan keterangan lengkap deskripsi produk',
        descriptionContent:
          'Klik pada tab "deskripsi" untuk melihat lebih lengkap keterangan dari produk yang akan Anda pesan',
        compositionTitle: 'Temukan keterangan komposisi dari produk',
        compositionContent:
          'Klik pada tab “komposisi” untuk melihat lebih lengkap kandungan dari produk yang akan Anda pesan',
        coreMoleculeTitle: 'Temukan keterangan komposisi inti dari produk',
        coreMoleculeContent:
          'Klik pada tab “komposisi inti” untuk melihat lebih lengkap kandungan dari produk yang akan Anda pesan',
        categoryTitle: 'Temukan informasi terkait kategori produk',
        categoryContent: 'Cek kategori produk dengan klik pada "kategori"',
        atcTitle: 'Temukan informasi terkain klasifikasi ATC produk',
        atcContent: 'Cek klasifikasi ATC produk dengan klik pada "Klasifikasi ATC"',
        manufacturerTitle: 'Temukan informasi terkait manufaktur produk',
        manufacturerContent: 'Cek manufaktur produk dengan klik pada "manufaktur"',
        shippingTitle: 'Temukan informasi terkait kebijakan pengiriman dan retur',
        shippingContent: 'Informasi lengkap tentang proses pengiriman dan pengembalian barang',
        tierTitle: 'Pilih pembelian minimal sesuai dengan diskon yang Anda inginkan',
        tierContent: 'Klik pada jumlah minimum yang Anda inginkan',
        ...tabsProductDetail,
      },
      homepage: {
        welcomeTitle: 'Selamat datang, di tampilan baru SwipeRx Belanja!',
        welcomeMessage: 'Lebih mudah penuhi kebutuhan apotek Anda dengan tampilan yang lebih menarik dan intuitif',
        loyaltyTitle: 'Klik di sini untuk mengakses SwipeRx Reward Pass Anda',
        loyaltyContent: 'Kumpulkan poin Anda dan redeem poin dengan hadiah menarik dari kami',
        mainBannerTitle: 'Temukan info dan promo terbaru di sini',
        mainBannerContent: 'Dapatkan informasi terbaru dan promosi menarik dari kami di sini',
        benefitsTitle: 'Keuntungan belanja di SwipeRx',
        benefitsContent: 'SwipeRx Belanja, aplikasi pengadaan apotek nomor 1',
        newProductsTitle: 'Temukan produk terbaru yang tersedia di aplikasi kami di sini',
        newProductsContent: 'Cari dan pesan produk yang Anda butuhkan',
        topWeeklyProductsTitle: 'Temukan produk terlaris setiap minggunya di sini',
        topWeeklyProductsContent: 'Setiap Senin, produk yang paling banyak dipesan akan ditampilkan di sini',
        campaignListTitle: 'Dapatkan informasi terbaru tentang promosi menarik dari kami',
        campaignListContent: 'Cari promosi dan hadiah menarik setiap bulannya di sini',
        hotDealsTitle: 'Temukan penawaran terbaik dari kami di sini',
        hotDealsContent: 'Kami bekerja sama dengan para PBF agar Anda bisa mendapatkan harga terbaik',
        featuredProductsTitle: 'Belanja produk pilihan khusus untuk Anda',
        featuredProductsContent: 'Temukan produk seasonal dan produk pilihan berdasarkan kebutuhan Anda',
        recentlyViewedTitle: 'Temukan kembali produk yang baru Anda lihat',
        recentlyViewedContent:
          'Untuk memudahkan pengalaman pembelian Anda, Anda selalu dapat mengakses produk yang Anda lihat sebelumnya',
      },
      loyaltyLandingPage: {
        welcomeTitle: 'Selamat datang di Halaman terbaru dari program loyalitas "Swiper Rewards pass"!',
        welcomeMessage:
          'Saat ini, informasi tentang Keanggotaan, Tingkatan dan Hadiah dapat dilihat secara Mudah, Interaktif & Menarik',
        membershipDetailTitle: 'Lihat Detail Keanggotaan Anda',
        membershipDetailMessage: 'Klik untuk melihat Detail Keanggotan dan Informasi Poin',
        activePointsTitle: 'Lihat Poin Aktif Anda',
        activePointsMessage: 'Lihat Poin yang dibutuhkan untuk Naik ke Level berikutnya',
        mostPopularRewardsTitle: 'Lihat Hadiah Paling Populer',
        mostPopularRewardsMessage: 'Klik Lihat Semua untuk mengetahui Top 50 Hadiah Paling Populer',
        howToGetPointsTitle: 'Lihat cara mendapatkan poin',
        howToGetPointsMessage:
          'Lihat cara untuk mendapatkan poin dengan melakukan beberapa langkah yang telah ditentukan',
        allRewardsTitle: 'Lihat Semua Hadiah',
        allRewardsMessage: 'Klik Lihat Semua untuk mengetahui Hadiah dengan Filter yang dapat disesuaikan',
        missionWelcomeTitle: 'Selamat datang di Halaman program Mission!',
        missionWelcomeMessage:
          'Dapatkan banyak hadiah dengan menyelesaikan Mission Anda. Anda dapat mengecek status Mission Anda di halaman ini. Ayo, selesaikan Mission-nya dan dapatkan hadiahnya!',
        featuredMissionsTitle: 'Lihat Misi Unggulan Anda',
        featuredMissionsMessage: 'Klik Lihat misi aktif/lihat semua untuk mengetahui semua misi aktif anda',
      },
      loyaltyMembershipPage: {
        welcomeTitle: 'Selamat datang di Halaman terbaru dari program loyalitas "Swiper Rewards pass"!',
        welcomeMessage:
          'Anda dapat melihat Informasi tentang Keanggotaan, Keuntungan, Poin dan Hadiah yang dapat ditukar',
        membershipDetailTitle: 'Lihat Detail Keanggotaan Anda',
        membershipDetailMessage: 'Lihat Poin yang dibutuhkan untuk mempertahankan Keanggotaan Anda',
        pointSummaryTitle: 'Ringkasan Poin dipindahkan ke Halaman Keanggotaan',
        pointSummaryMessage: 'Lihat Pengunaan Poin Anda',
        myRewardsTitle: 'Lihat Hadiah yang Anda Tukarkan',
        myRewardsMessage: 'Klik Lihat Semua untuk mengetahui Hadiah yang telah ditukar',
        membershipBenefitsTitle: 'Keuntungan Anggota',
        membershipBenefitsMessage: 'Lihat keuntungan yang anda dapat di Tingkatan saat ini',
      },
      loyaltyHotRewardPage: {
        welcomeTitle: 'Selamat datang di Halaman Hadiah Paling Populer',
        welcomeMessage: 'Saat ini, fitur filter dan pencarian dapat digunakan untuk mencari hadiah dengan mudah',
        searchTitle: 'Fitur Baru dalam mencari Hadiah',
        searchMessage: 'Anda dapat mencari Hadiah secara spesifik berdasarkan namanya',
        pointsRequiredTitle: 'Filter Terbaru Berdasarkan Poin yang Dibutuhkan',
        pointsRequiredMessage: 'Anda dapat filter hadiah berdasarkan Kebutuhan poin minimum dan maksimum ',
        vendorTitle: 'Filter Terbaru Berdasarkan Vendor',
        vendorMessage: 'Sekarang, Anda dapat memfilter hadiah berdasarkan vendor',
        categoryTitle: 'Filter Terbaru Berdasarkan Kategori',
        categoryMessage: 'Sekarang, Anda dapat memfilter hadiah berdasarkan kategori',
      },
      loyaltyMyRewardPage: {
        welcomeTitle: 'Selamat datang di halaman terbaru dari Hadiah Saya!',
        welcomeMessage: 'Sekarang, pencarian dapat digunakan untuk dengan mudah mencari hadiah yang telah ditukarkan',
        searchTitle: 'Fitur Pencarian Terbaru untuk mencari Hadiah Saya',
        searchMessage: 'Anda dapat mencari hadiah yang sudah ditukarkan berdasarkan nama',
      },
      loyaltyMissionCataloguePage: {
        [LoyaltyMissionStatus.ACTIVE]: {
          welcomeTitle: 'Welcome to the Mission Catalogue Page',
          welcomeMessage: 'This is the welcome message of active missions',
          missionTitle: 'This is the Title of Active Mission List',
          missionMessage: 'This is the message of active mission list',
        },
        [LoyaltyMissionStatus.COMPLETED]: {
          welcomeTitle: 'Welcome to the Mission Catalogue Page',
          welcomeMessage: 'This is the welcome message of completed missions',
          missionTitle: 'This is the Title of Completed Mission List',
          missionMessage: 'This is the message of completed mission list',
        },
        [LoyaltyMissionStatus.EXPIRED]: {
          welcomeTitle: 'Welcome to the Mission Catalogue Page',
          welcomeMessage: 'This is the welcome message of expired missions',
          missionTitle: 'This is the Title of Expired Mission List',
          missionMessage: 'This is the message of expired mission list',
        },
      },
      loyaltyMissionPage: {
        welcomeTitle: 'Welcome to the Mission Page',
        welcomeMessage: 'This is the welcome message',
        infoTitle: 'This is Mission Info Title',
        infoMessage: 'This is mission info message',
        levelTitle: 'This is Mission Level Title',
        levelMessage: 'This is mission level message',
        tncTitle: 'This is Mission TNC Title',
        tncMessage: 'This is mission tnc message',
      },
      productListingPage: {
        filterModalTitle: 'menu saringan',
        filterModalContent: 'This menu will appear after you click the Sort & Filter button',
        filterToolbarTitle: 'Kami telah memperbaharui pilihan filter',
        filterToolbarContent:
          'Selain alfabet, kami menambah opsi filter dan urutan lainnya untuk mempermudah proses pencarian barangmu.',
        filterButtonTitle: 'Change order filter options',
        filterButtonContent: 'Click here again to change the order and filter options.',
      },
    },
    cartModal,
  },
  tierDiscount: {
    getUpTo: 'Dapatkan diskon hingga {{discount}}%',
    buyMinimum: 'Pembelian minimal {{quantity}}',
    getDiscount: 'Dapatkan diskon {{discount}}%',
    promoApplied: '{{discount}}% diskon berlaku!',
  },
  tierDiscountSheet: {
    getUpTo: 'Dapatkan diskon hingga {{discount}}%',
    getDiscount: 'Diskon {{discount}}%',
    buyMinimum: '(Beli min.{{quantity}})',
    buy: 'Beli',
  },
  stockOut: {
    outOfStock: 'Habis terjual',
    lowOnStock: 'Kurang dari {{stockLeft}} unit tersisa!',
    maxQtyReached: 'Kuantitas maksimum tercapai',
    notify: 'Kabari saya',
    cardTitle: 'Maaf, produk ini habis terjual.',
    cardDescription: 'Klik “Kabari” untuk mendapatkan informasi jika produk telah tersedia',
    dialogTitle: 'Pengingat produk telah diaktifkan!',
    dialogDescription: 'Kami akan mengabari Anda jika produk telah tersedia lagi',
    okay: 'Oke',
    reminderRemoval: 'Akan hilang dari halaman "Kabari Saya" pada {{datetime}}. Ayo segera beli!',
    maxDailyQtyLimit: 'MAX {{value}}/HARI',
    maxMonthlyQtyLimit: 'MAX {{value}}/BULAN',
    maxQtyLimitReached:
      'Kuantitas maksimum tercapai, Anda hanya dapat memesan {{ availableQty }} unit(s) dari produk ini perharinya',
    maxQtyMonthlyLimitReached: `Kamu akan mencapai batas maksimum bulanan {{value}} untuk produk ini setelah checkout. Jika Anda membutuhkan pembelian setelah checkout, Anda dapat melakukan pembelian kembali di bulan depan atau mohon menghubungi tim Customer Service kami untuk mendapatkan layanan lebih lanjut.`,
  },
  cartPage: {
    cartItems: 'Ada {{itemCount}} produk di keranjang Anda.',
    getPrices: 'Cari harga terbaik',
    noItems: 'Tidak ada produk di keranjang Anda.',
    oneItem: 'Ada 1 produk di keranjang Anda.',
    receivePrices: 'Mendapatkan harga terbaik dengan cepat',
    requestPrices: 'Meminta harga untuk penawaran terbaik',
    search: 'Mencari produk',
    yourCart: 'Keranjang Anda',
    unavailableProduct: 'Beberapa produk dari keranjang Anda telah dihapus karena tidak tersedia.',
    viewCart: 'Lihat Keranjang',
  },
  errorPage: {
    closeApp: 'Tutup aplikasi',
    contactMessage: 'Bila Anda membutuhkan bantuan tambahan, silakan hubungi kami melalui email di beta@swiperxapp.com',
    contactUs: 'Hubungi kami',
    error: 'Error: $t(errors:{{message}})',
    pressingButton: 'atau dengan cara menekan tombol di bawah ini',
    sorryMessage: 'Maaf, telah terjadi kendala',
    tryAgain: 'Silahkan coba lagi',
  },
  errors: {
    'Activation request has already been approved': 'Permintaan aktivasi sudah disetujui',
    'Activation request has already been submitted': 'Permintaan aktivasi sudah dilakukan',
    'Could not send activation request email': 'Tidak dapat mengirim permintaan email aktivasi',
    'No active bank account has been set up for the user':
      'Tidak ada akun bank yang telah didaftarkan untuk pengguna ini',
    'Order does not exist': 'Pesanan tidak tersedia',
    'Organization id or name must be provided': 'ID organisasi atau nama harus diberikan',
    'Organization not found': 'Organisasi tidak dapat ditemukan',
    'products parameter must be provided': 'parameter produk harus diberikan',
    'Request timed out': 'Waktu layanan permintaan habis',
    'Session does not exist': 'Sesi tidak tersedia',
    'Successfully requested for activation!': 'Telah berhasil melakukan permintaan aktivasi',
    'Search query must be provided': 'Pertanyaan pencarian harus disediakan',
    'Successfully updated cart!': 'Telah berhasil memperbaharui keranjang Anda!',
    'The net amount after tax exceeds your remaining balance': 'Total nilai setelah pajak melebihi sisa kredit anda',
    'User has not yet requested for activation': 'Pengguna belum melakukan permintaan aktivasi',
    'User account has not yet been activated': 'Akun pengguna belum di aktivasi',
    'You do not have access to this order': 'Anda tidak memiliki akses untuk pesanan ini',
    'Network Problem': 'Terjadi kesalahan atau gangguan jaringan.',
  },
  helpPage: {
    chatMessage: 'Hai, saya membutuhkan bantuan mengenai',
    chatWithUs: 'Berbincanglah dengan kami',
    contactUs: 'Klik disini untuk menghubungi kami',
    gladToHelp: 'Tim layanan kami tersedia untuk membantu Anda memesan produk',
    helpOrdering: 'Membutuhkan bantuan untuk memesan?',
    title: 'Bantuan dan dukungan',
  },
  creditLimitRequest: {
    title1: 'Permintaan',
    title2: 'Batas Kredit',
    step: 'Langkah',
    step1: 'Isi dahulu formulir berikut, untuk mempercepat proses peninjauan',
    step2: 'Selanjutnya tim kami akan meninjau permintaan kenaikan batas kredit Anda',
    currentCreditLimit: 'Batas kredit Anda saat ini:',
    inputYourNewCreditLimit: 'Masukkan permintaan kredit limit',
    termsAndCondition: 'Dengan mengklik LANJUTKAN, Anda menyetujui syarat dan ketentuan.',
    continue: 'LANJUTKAN',
    formLinkLabel: 'Link Formulir',
    overLimitMessage:
      'Mohon maaf, credit limit yang sedang anda ajukan telah melebihi batas maksimal pengajuan penambahan credit limit via aplikasi SwipeRx. Info lebih lanjut dapat menghubungi CS',
    supportLinkMessage:
      'Saya {{userName}}, ingin mengajukan penambahan Credit Limit untuk apotek {{pharmacyName}} sebesar {{creditLimit}}',
    supportLinkLabel: 'link WA',
    errorRequestLimit: 'Permintaan batas kredit baru, lebih kecil dari batas kredit anda sekarang',
  },
  creditLimitRequestStatus: {
    appBarTitle: 'Permintaan Batas Kredit',
    requestInProgress: 'Permintaan Anda sedang diproses',
    requestInProgressSubtitle:
      'Tim kredit kami sedang meninjau kiriman Anda dan akan memperbarui Anda setelah selesai.',
    requestAccepted: 'Selamat!\nPermintaan Anda telah disetujui.',
    newCreditLimit: 'Limit kredit Anda sekarang menjadi {{newCreditLimit}}',
    requestRejected: 'Mohon maaf sekali untuk permintaan Anda saat ini belum dapat kami penuhi.',
    reason: 'Alasan',
    approvalNotes: 'Catatan',
    requestAgain: 'Ajukan lagi',
    thankYou: 'Terima kasih telah mengajukan permintaan.',
    willInform: 'Kami akan memperbarui informasi tentang status pengajuan kredit Anda dalam 2-3 hari kerja',
    checkProgress: 'Cek status',
  },
  increaseCreditPage: {
    apply: 'Ajukan kredit limit yang lebih besar',
    chatMessage: 'Hai, apakah saya bisa mendapatkan kredit limit yang lebih besar?',
    orderLimit: 'Total pembelian/belanja anda adalah',
    text: {
      line1:
        'Anda dapat secara otomatis meningkatkan limit kredit Anda menjadi Rp 20.000.000,- dengan bertransaksi dan membayar tagihan Anda secara tepat waktu. Silakan mengikuti 3 langkah mudah ini.',
      line2: 'Transaksi senilai Rp 8.000.000,- setiap bulannya selama tiga bulan',
      line3: 'Lakukan pembayaran faktur dalam waktu 37 hari dari tanggal faktur selama 3 bulan',
      line4:
        'Kami akan secara otomatis meningkatkan limit Anda menjadi Rp 20.000.000,- dan akan memberitahu Anda ketika limit Anda dinaikan',
      line5:
        'Dapatkan kredit limit sampai dengan Rp 500.000.000 dari Modalku dengan cara mengirimkan rekening koran tabungan anda(minimal 3 bulan transaksi ). Hubungi layanan pelanggan kami (beta@swiperxapp.com) di jam kerja dan kami akan membantu Anda untuk proses aplikasi tersebut.',
    },
    title: 'Tingkatkan kredit limit Anda',
  },
  orderHeader: {
    deliveryDate: 'Tanggal pengiriman',
    invoiceDate: 'Tanggal faktur',
    invoiceNumber: 'Nomor faktur',
    orderDate: 'Tanggal pesanan',
    orderNumber: 'Nomor pesanan',
    paymentDueDate: 'Jatuh tempo pembayaran',
    status: {
      pending: 'Pending',
      accepted: 'Diterima',
      delivered: 'Terkirim',
      completed: 'Lunas',
    },
  },
  orderPage: {
    completeOrder: 'Pemesanan selesai',
    deliveryProof: 'Bukti pengiriman',
    discount: 'Diskon',
    documents: 'Dokumen',
    invoice: 'Faktur',
    viewTaxInvoice: 'View Tax Invoice',
    noTaxInvoice: 'Faktur pajak belum dikrim atau disetujui.',
    orderDetail: 'Rincian pesanan',
    products: 'Produk',
    subtotal: 'Subtotal',
    subtotalDiscount: 'Subtotal setelah diskon',
    totalAmount: 'Total nilai pesanan',
    totalAmountAfterVat: {
      total: 'Total pesanan',
      vat: 'Setelah PPn',
    },
    unit: 'unit',
    units: 'unit',
    vatAmount: 'PPn',
    prekursor: {
      title: 'Prekursor Document',
      add: 'Tambah Prekursor Document',
      uploadSuccess: {
        title: 'Hore! gambar dokumen prekursor Anda berhasil diunggah',
        info: 'Anda dapat memeriksa status pesanan ini di tab Aktivitas',
        action: 'BAIK',
      },
      uploadFailed: {
        title: 'Ups! Pengunggahan dokumen prekursor Anda gagal',
        info: 'File gagal:',
        action: 'MENCOBA KEMBALI',
      },
      uploadApprovedFailed: {
        title: 'Dokumen sudah di setujui, tidak dapat melakukan update',
        info: 'failed file:',
        action: 'RETRY',
      },
      prekursorTooltipTour: {
        preUpload: 'Klik di sini untuk mengunggah dokumen Prekursor',
        postUpload: 'Ketuk di sini untuk melihat atau mengedit dokumen Prekursor',
        editUpload: 'Ketuk di sini untuk mengedit dokumen Prekursor',
      },
    },
  },
  ordersPage: {
    deliveryDue: 'Jadwal pengiriman',
    history: 'Riwayat transaksi',
    increaseCreditLimit: 'Tingkatkan kredit limit Anda',
    learnMore: 'Pelajari lebih lanjut',
    orderDate: 'Tanggal pesanan',
    orderNumber: 'Nomor pesanan',
    paymentDue: 'Jatuh tempo pembayaran',
    paymentDueDate: 'Tanggal jatuh tempo pembayaran',
    howToPay: 'Cara membayar',
    contactNow: `Silahkan hubungi pelanggan sukses kami via {{callCenterNumber}} jika anda memiliki pertanyaan`,
    totalAfterVat: {
      afterVat: 'Setelah PPn',
      total: 'Jumlah total',
    },
    viewDetails: 'Lihat rincian',
    status: {
      pending: 'Pending',
      accepted: 'Diterima',
      delivered: 'Terkirim',
      completed: 'Lunas',
    },
    goToSearch: 'Go to search',
    pendingPayments: 'Hutang',
    daysPastDue: 'hari lewat jatuh tempo',
    Due: 'due',
    invoiceNotYetIssued: 'in progress',
    orderID: 'No. Pesanan',
    noPaymentsDueToday: 'Tidak ada pembayaran jatuh tempo hari ini',
    payEarly: 'Anda dapat mendapatkan kembali Kredit anda dengan cara membayar lebih awal',
    selectAnotherDate: 'Pilih tanggal lain untuk cek tagihan yang jatuh tempo',
    overdueInvoices: 'You have overdue invoices. Click on the button to see how much you need to pay',
    noOrders: 'Tidak ada pesanan saat ini',
    serchForAProduct: 'Cari produk untuk mulai membeli',
    schedulePayment: 'Jadwal pembayaran',
    totalDueAmount: 'Total Tagihan Jatuh Tempo',
    noPaymentDueBy: 'Tidak ada tagihan jatuh tempo pada',
    recentOrders: 'Pesanan Terbaru',
    viewAll: 'Lihat semua',
    noRecentOrders: 'Tidak ada pesanan terbaru',
    youHaveNoTransactions: 'Search for a product to start purchasing.',
    viewCompleteHistory: 'Lihat semua riwayat pesanan',
    COH: 'Semua Riwayat Pesanan',
    back: 'Kembali',
    loading: 'Memuat...',
    done: 'Selesai',
    paidOn: 'Lunas tanggal:',
    selectDueDateHead: 'Pilih tanggal jatuh tempo',
    selectDate: 'Pilih tanggal yang kamu mau untuk pembayaran dan lihat berapa jumlah tagihan kamu',
    bankTransfer: 'Transfer bank',
    howToPayDetails: 'Anda bisa melakukan pembayaran lebih awal dengan menggunakan informasi bank dibawah ini.',
    transfer: 'Transfer',
    on: 'pada',
    toThisBankAccount: 'ke rekening bank berikut',
    bank: 'Bank',
    accNum: 'Nomor Rekening',
    accName: 'Nama Rekening',
    cashPay: 'Pembayaran Tunai di Apotek',
    prepare: 'Siapkan',
    cashInPharmacy: 'uang tunai di Apotek kamu dan jadwalkan penagihan tunai dengan menghubungi Customer Success kami',
    selectDateSchedule: 'Pilih tanggal',
    endOfPage: 'Anda sudah sampai pada bagian akhir halaman ini',
    checkThisDate: 'Cek tanggal berikut',
    paidOnTime: 'paid',
    partialPayment: 'memproses pembayaran',
  },
  payPage: {
    bank: {
      accountNumber: 'Nomor account',
      label: 'Bank',
      name: 'Nama',
    },
    contactNow: `Silahkan hubungi pelanggan sukses kami via {{callCenterNumber}} jika anda memiliki pertanyaan`,
    contactService: 'Hubungi layanan pelanggan kami sekarang untuk menjadwalkan penagihan tunai',
    dueAmount: 'Total Tagihan Jatuh Tempo',
    method1: 'Metode 1: Transfer Bank',
    method2: 'Metode 2: Pembayaran tunai di apotek',
    noBankAcc: 'Belum ada informasi detil bank untuk akun ini. Mohon hubungi layanan pelanggan kami',
    howToPay: 'Cara membayar',
    title: 'Bayar sekarang',
    totalAmountOrdered: 'Jumlah total pesanan',
    dueNow: 'Jatuh tempo sekarang',
    orderNumber: 'Nomor Pesanan',
    orderDate: 'Tanggal Pesanan',
    vat: 'Setelah PPn',
    dueWeek: 'Jatuh tempo dalam waktu satu minggu',
    due: 'Jatuh tempo',
  },
  paymentStatus: {
    due: {
      label: 'Jatuh tempo',
      message: 'Bayar lebih awal dan tingkatkan limit pesanan Anda',
    },
    dueSoon: {
      label: 'Akan segera jatuh tempo',
      message: 'Bayar lebih awal dan tingkatkan kredit limit Anda',
    },
    overDue: {
      label: 'Tagihan Jatuh Tempo',
      message: 'Bila telah melakukan pembayaran abaikan pesan ini',
    },
  },
  pricesPage: {
    yourAccountWasDowngraded: 'Ups! Status akun Anda diturunkan.',
    downgradedAccountAction: 'Harap perbarui lisensi Anda untuk membeli produk ini',
    verifyAccount: 'Verifikasi akun Anda',
    acceptBuy: 'Terima dan beli',
    agree: 'Saya setuju',
    availableCredit: 'Kredit yang tersedia',
    bestPrices: 'Kami telah menemukan harga terbaik dari PBF',
    completeOrder: 'Pesanan Anda yang telah selesai',
    continue: 'Lanjutkan',
    creditAfterOrder: 'Kredit setelah pemesanan',
    creditLine: 'Jalur Kredit',
    creditForOrder: 'Kredit setelah order ini (setelah PPn)',
    decline: 'Saya tidak setuju',
    deliveryTerms: 'Syarat pengiriman',
    distributor: 'Distributor',
    manyCreatedOrders: 'Nomor pesanan Anda adalah',
    goBack: 'Kembali',
    purchase: 'Beli',
    confirmation: 'Anda yakin ingin melanjutkan?',
    goodNews: 'Kabar gembira!',
    helloBoss: 'Hello!',
    prekursorDocumentRequired: 'Anda harus mengunggah dokumen Prekursor setelah konfirmasi pesanan',
    readyToReceiveOrders: 'Distributor kami sekarang siap melayani pesanan Anda.',
    tryToFixTheFollowingOrders:
      'Tampaknya kami memiliki beberapa masalah dengan pemesanan Anda. Mohon cek kembali pesanan anda',
    fullfillOrders: 'untuk memenuhi pemesanan Anda!',
    weFound: 'Kami telah menemukan',
    yourOrder: 'Pesanan Anda',
    below: 'belum',
    orders: 'Aktivitas',
    cartTotal: 'Anda memiliki {{total}} produk di keranjang Anda.',
    needsToBeAdjusted: '<0>Pesanan</0> perlu disesuaikan.',
    ordersReadyToServed: '<0>Pesanan</0> anda sudah siap',
    paymentDate: 'Lakukan pembayaran pada {{date}}',
    subtotal: 'Subtotal',
    minimumInvoice: 'Total pesanan Anda di bawah minimum invoice\n<0>{{amount}}<0>.',
    addMoreProduct: 'Tambahkan lebih banyak produk',
    minimumInvoiceDesc: 'Silakan tambahkan kuantitas pesanan, atau pesan produk lainnya.',
    enoughCredit: 'Anda memiliki cukup <0>kredit</0> untuk pemesanan Anda.',
    notEnoughCredit: 'Anda tidak memiliki cukup kredit untuk pemesanan Anda.',
    orderTotal: 'Total pesanan',
    totalDiscount: 'Anda telah menghemat {{amount}}!',
    totalOrderBelowMinimum: {
      itSeems: 'Hmm, sepertinya total pesanan Anda untuk',
      belowMinimum: 'masih dibawah minimum ',
    },
    howCanIProceed: 'Bagaimana saya bisa proses?',
    clickHereToProceed: 'Klik disini untuk melanjutkan',
    proceedWithOrder: 'Lanjutkan pemesanan',
    noAlternativeProductsFound: 'Tidak terdapat produk alternatif untuk saat ini',
    close: 'Tutup',
    orderSummary: 'Ringkasan pesanan',
    eVat: 'PPN',
    totalAfterVat: 'Jumlah Pesanan (Setelah Pajak)',
    includesVat: 'Termasuk Pajak {{ taxRate }}',
    paymentSummary: 'Ringkasan pembayaran',
    creditForThisOrder: 'Kredit untuk pemesanan ini (setelah PPN)',
    notEnoughCreditForOrder: 'Maaf, Anda tidak memiliki kredit yang cukup untuk pesanan.',
    notEnoughCreditForOrderSubtitle: 'Silakan lakukan pembayaran kredit terlebih dahulu',
    payCredit: 'Lakukan pembayaran',
    looksLikeNotEnoughCredit: 'Sepertinya Anda tidak memiliki kredit yang cukup untuk pesanan ini.',
    looksLikeBelowMinimumInvoiceAmount: 'Sepertinya Anda berada di bawah minimum nilai faktur',
    letsTryTheFollowingOptions: 'Mari kita coba opsi berikut',
    reduceProductQuantity: 'Kurangi jumlah pada beberapa produk',
    increaseProductQuantity: 'Tingkatkan jumlah beberapa produk',
    cancelDistributorOrder: 'Membatalkan pemesanan untuk {{ distributor }}',
    cancelOrder: 'Membatalkan pemesanan',
    confirmCancelOrder: 'Apakah kamu yakin untuk membatalkan pemesanan ke distributor ini?',
    thisWillRemoveProductsBelow:
      'Hal ini hanya akan menghapus produk berikut. Produk lain akan tetap disediakan oleh distributor lain',
    otherDistributorsOfferingSameProduct:
      'Distributor lain menawarkan produk yang sama dengan harga yang lebih tinggi.',
    transferSomeProductsBelow: 'Mengirim beberapa dari produk berikut',
    weHaveCancelledYourOrder: 'Kami telah membatalkan pemesanan untuk distributor ini',
    proceedToYourBasket: 'Melanjutkan ke keranjang',
    transferProducts: 'Mengirim produk',
    currentlyLookingForAlternatives: 'Saat ini kami sedang mencari distributor lain, mohon menunggu...',
    transferAllProducts: 'Mengirim semua produk',
    transferProductsToAnotherDistributor: 'Mengirim produk ke distributor lain',
    productMoved: 'Produk telah dihapus karena tidak tersedia di distributor lain.',
    productMovedDueToPriceDecrease: 'Produk ini telah di pindahkan ke alternatif distributor lain yang lebih baik.',
    confirmOrder: 'Konfirmasi pemesanan',
    waitAMinute: 'Hmm, tunggu sebentar..',
    forAssistance: 'Untuk bantuan, silakan hubungi tim',
    customerSupportViaWhatsApp: `support kami {{callCenterNumber}}`,
    goBackAndCheck: 'Periksa Kembali',
    ok: 'Ok',
    orderNow: 'Lanjutkan {{ totalItem }}',
    earnPoints: 'Dapatkan <0>{{ points }}</0>',
    proceedPurchase: 'Apakah Anda ingin melanjutkan pemesanan Anda?',
    submitOrderTo: 'Ini akan mengirimkan pesanan ke',
    pleaseUploadPrekursorDocument: `Dan harap mengunggah dokumen prekursor pada tab menunggu dokumen setelah konfirmasi pesanan ini`,
    reason: 'Alasan:',
    productNoLongerAvailable: 'Produk tidak tersedia untuk saat ini.',
    membershipChanged: 'Status Anda diturunkan. Mohon berikan lisensi terbaru kamu untuk membeli produk ini',
    sipnapLicenseRequired: 'Pembelian Produk wajib melampirkan tanda daftar SIPNAP',
    maxQtyLimitReached: `Anda telah mencapai jumlah maksimum pembelian untuk produk ini. Anda dapat membeli produk ini lagi besok`,
    maxQtyMonthlyLimitReached: `Anda telah mencapai limit maksimum pembelian per bulan untuk produk ini. Silakan melakukan pembelian di bulan berikutnya`,
    priceIncrease: 'Harga naik sejak penutupan terakhir.',
    priceDecrease: 'Harga turun dari {{ price }}',
    tour: {
      start: 'Mulai tur',
      welcomeTitle: 'Selamat datang di aplikasi SwipeRx Belanja!',
      welcomeContent: 'Kita cek fitur terbaru di aplikasi SwipeRx Belanja, yuk!',
      overviewTitle: 'Ringkasan keranjang',
      overviewContent: 'Temukan disini informasi tentang produk yang ingin Anda pesan',
      productTitle: 'Produk',
      productContent: 'Di bagian ini Anda bisa melihat semua produk yang ditambahkan',
      orderSummaryTitle: 'Ringkasan pesanan',
      orderSummaryContent: 'Ini jumlah total untuk pesanan anda',
      paymentSummaryTitle: 'Ringkasan pembayaran',
      paymentSummaryContent:
        'Di bagian ini Anda bisa melihat apakah anda memiliki kredit yang cukup untuk pesanan Anda',
      deliveryTermsTitle: 'Ketentuan pengiriman',
      deliveryTermsContent: 'Lihat ketentuan pengiriman untuk pesanan Anda',
      orderNowTitle: 'Pesan sekarang ',
      orderNowContent: 'Apabila sudah sesuai, klik “Pesan“ sekarang',
      product: {
        productCardTitle: 'Detail Produk',
        productCardContent:
          'Semua produk yang ada stok kini akan tampil paling atas. Untuk produk yang sedang kehabisan stok, kamu dapat menggeser layar mu ke paling bawah.',
        productImageTitle: 'Gambar produk',
        productImageContent: 'Anda juga dapat memperbesar gambar produk dengan menekan gambar produk',
        productAddContent: 'Tekan "tambah" untuk menambahkan produk ke keranjang Anda',
        productQuantityContent:
          'Sekarang lebih mudah untuk mengubah kuantitas produk Anda. Klik tombol tambah (+) untuk menambah produk atau tombol minus (-) untuk mengurangi produk',
        viewCartTitle: 'Cek keranjang',
        viewCartContent: 'Informasi keranjang anda',
        searchProductByLetterContent: 'Pilih huruf untuk filter produk',
      },
    },
    minValue: {
      heading: 'Pesanan Anda tidak memenuhi nilai pesanan minimum',
      increase: 'Mohon tingkatkan total pesanan Anda atau tambah produk',
      minValue: 'Pesanan Anda setidaknya harus {{minAmount}}',
    },
    belowMinimum: {
      title: 'Anda perlu mengecek ulang pesanan',
      subTitle: 'Pesanan Anda tidak memenuhi nilai pesanan minimum',
      actionButton: 'Kembali ke pesanan',
    },
    noEnoughCredit: {
      title: 'Ups! Anda tidak memliki cukup kredit untuk melakukan pesanan',
      subTitle: 'Silakan lakukan pembayaran ke kredit Anda terlebih dahulu atau periksa keranjang Anda',
      actionButton: 'Lakukan pembayaran',
      cancel: 'Batal',
    },
    suspended: {
      heading: 'Akun anda ditangguhkan untuk sementara',
      reason: 'Alasan penangguhan',
    },
    dpd: {
      blockMessage:
        'Akun Anda diblokir. Ada {{pastDueInvoiceCount}} tagihan yang belum dibayar hingga {{dpd}} hari. Segera lunasi agar bisa belanja lagi.',
    },
    voucher: {
      info: 'Anda dapat memasukkan Kode Voucher setelah melakukan konfirmasi pembelian produk',
      chooseVoucher: 'Pilih Voucher',
      voucherApplied: 'Voucher {{amount}} Berlaku!',
    },
    oops: 'Oops!',
    encounteredAnErrorWithYourOrder: 'Sepertinya kami menemukan beberapa kesalahan pada order anda',
    productsChangedAvailability: 'Ada produk yang mengalami perubahan harga dan ketersediaan. Mohon cek kembali.',
    orderPaymentError: 'We encountered an error with the payment provider. Please try again later',
    oneCreatedOrder: 'Nomor pesanan Anda adalah',
    oneDistributor: '1 distributor sedang melayani Anda',
    products: 'Produk',
    items: 'items',
    item: 'item',
    orderFor: 'Pesanan untuk',
    searchMore: 'Cari lagi',
    servingDistributors: '{{distributorCount}} distributor sedang melayani Anda',
    success: 'Kami telah berhasil memproses pesanan Anda!',
    youHaveNoProductOnCart: 'Anda tidak memiliki produk di keranjang Anda.',
    timeToCreateAnOrder: 'Saatnya membuat pesanan!',
    firstProductsText: 'Langkah pertama untuk menemukan harga terbaik adalah menemukan produk yang Anda butuhkan.',
    searchProducts: 'Cari Produk',
    title: 'Keranjang saya',
    totalAmount: {
      total: 'Jumlah total',
      vat: 'Setelah PPn',
    },
    totalCreditLimit: 'Total kredit limit',
    totalOrderAmount: 'Total Jumlah Pesanan',
    vat: 'PPn ({{amount}})',
    weFoundBestPrices: 'Yay, kami telah temukan yang terbaik',
    payment: {
      heading: { id: 'Persyaratan Kredit' },
      terms: { id: 'Jangka waktu pembayaran 30 hari' },
    },
    delivery: {
      deliveryHours: {
        id: 'Untuk area Jadetabek pengiriman hari yang sama tersedia untuk pesanan yang masuk sebelum pukul 11.00 siang, kecuali hari Minggu dan hari libur nasional, dan pesanan yang masuk setelah pukul 11.00 siang akan diproses di hari berikutnya.',
      },
      heading: { id: 'Pengiriman' },
      conditions: {
        id: 'Produk akan dikirimkan antara pukul 05.00 sore hingga 10.00 malam setelah PBF memproses pesanan.',
      },
      conditions2: {
        id: 'Untuk area Bogor dan area terdekat di sekitarnya, pengiriman akan dilakukan di hari berikutnya mulai pukul 09.00 pagi untuk pesanan yang masuk sebelum pukul 11.00 siang, kecuali hari Minggu dan hari libur nasional, dan pesanan yang masuk setelah pukul 11 siang akan dikirimkan 2 hari setelah pemesanan.',
      },
      terms: {
        id: 'Apabila waktu pengiriman tidak sesuai atau Anda tidak dapat menerima pengiriman tersebut, silahkan hubungi tim Customer Success agar dapat kami jadwalkan ulang pengiriman',
      },
    },
    return: {
      heading: { id: 'Kebijakan Pengembalian' },
      conditions: {
        id: 'Kebijakan pengembalian berdasarkan perusahaan\nPada saat pengiriman, pengembalian kedaluwarsa didasarkan pada perusahaan.',
      },
    },
    contact: {
      heading: { id: 'Customer Service' },
      terms: { id: '' },
      conditions: { id: '' },
      line: { id: '' },
      phone: { id: '' },
      officeHour: { id: '' },
    },
    unavailableProduct: 'Beberapa produk dari keranjang Anda telah dihapus karena tidak tersedia.',
    lowOnStock: 'Kurang dari {{stockLeft}} unit tersisa!',
    maxQtyReached: 'Kuantitas maksimum tercapai',
    outOfStock: 'Habis terjual',
    notify: 'Kabari saya',
    unnotify: 'Batalkan pengingat',
    unpaidOrder: 'Pesanan Menunggu',
    limitedPaymentInfo:
      'Anda masih memiliki pesanan yang menunggu. Mohon selesaikan pembayaran tersebut terlebih dahulu.',
    clearCart: {
      title: 'Are you sure?',
      content: 'Anda yakin ingin menghapus {{itemCount}} produk dari keranjang?',
      no: 'Tidak',
      delete: 'menghapus',
    },
    addedToFavorite: 'Ditambahkan ke wishlist',
    removedFromFavorite: 'Dihapus dari wishlist',
    selectAll: 'Pilih semua',
    getProductSoon: 'Dapatkan produknya segera',
    seeDeliveryEstimation: 'Dapatkan produknya tanggal <0>{{ estimationDate }}</0>',
    checkoutBeforeCutoff: 'Checkout sebelum jam cut-off {{cutoffTime}}untuk pengiriman sesuai tanggal estimasi',
    prekursorDeliveryCondition:
      'Untuk menerima barang berikut ini, diharapkan untuk segera mengunggah dokumen Prekursor setelah checkout.',
    instruction: 'Instruksi',
    prekursorDelivery: 'Produk Prekursor',
    deleteConfirmation: {
      prefix: 'Apakah Anda yakin ingin menghapus',
      label: 'item\n yang dipilih',
      suffix: 'dari keranjang Anda?',
      cancel: 'Batal',
      confirm: 'Hapus',
    },
    cartChangedConfirmation: {
      title: 'Ups! Anda perlu memeriksa ulang keranjang Anda',
      subtitle: ' Pesanan Anda perlu disesuaikan.',
      close: 'Kembali ke Keranjang Saya',
    },
    maxDailyQtyLimit: 'MAX {{value}}/HARI',
    maxMonthlyQtyLimit: 'MAX {{value}}/BULAN',
    maxPurchaseLimitReached: {
      alert: {
        titleOneProduct: `Oops! Jumlah pemberian barang yang Anda masukkan telah melebihi jumlah maksimal/hari untuk produk {{ productName }}`,
        titleManyProducts: `Ups! Anda telah mencapai jumlah maksimum/hari yang diperbolehkan untuk beberapa produk`,
        adjustQty: 'Harap sesuaikan jumlah produk',
        changeProductQty: 'mengubah kuantitas produk',
        cancel: 'Membatalkan',
      },
      summary: {
        maxPurchaseLimitReached: "You've reached the maximum unit(s) allowed for the purchase of the item.",
        pleaseAdjustQtyOneProduct: 'Please adjust the {{ productName }} quantity below',
        pleaseAdjustQtyManyProducts: 'Please adjust the products quantity below',
        changeProductQty: 'change product quantity',
      },
    },
    findSimilarProduct: 'Temukan Serupa',
    remindMeModalTitle: 'Pengingat produk telah diaktifkan!',
    remindMeModalBody: 'Kami akan mengabari Anda jika produk telah tersedia lagi.',
    okay: 'Oke',
  },
  requestingPage: {
    checking: 'Kami sedang mencari harga terbaik dari PBF untuk Anda. Ini akan membutuhkan waktu beberapa saat.',
    findingProducts: 'Sedang menemukan harga terbaik untuk Anda',
  },
  searchPage: {
    addProduct: 'Tambah produk',
    addToCart: 'Masukan ke keranjang',
    alreadyOrdered: 'Anda telah memesan ini sebelumnya',
    buy: 'Beli',
    buyAgain: 'Beli lagi',
    cartItems: 'Ada {{itemCount}} produk di keranjang Anda.',
    checkout: 'Keluar',
    noItems: 'Saat ini tidak ada produk di keranjang Anda.',
    noProducts,
    oneItem: 'Ada 1 produk di keranjang Anda.',
    packaging: 'Kemasan',
    quantity: 'Jumlah',
    recentPurchases: 'Pembelian Anda yang terakhir',
    searchPlaceholder: 'Cari 3000+ produk di sini',
    searchResults: 'Hasil pencarian',
    productIsUnavailable: 'Saat ini produk sedang tidak tersedia',
    notifyMe: 'Beritahu saya bila sudah tersedi',
    notifyInfo: 'Anda akan mendapatkan pemberitahuan setelah produk tersedia kembali',
    availableProducts: 'Produk Tersedia',
    all: 'Semua',
    jumpLetter: 'Cari berdasarkan huruf A',
    back: 'Kembali',
    getBackToYouSoon: 'Kami akan segera menghubungi Anda kembali!',
    recentlyPurchased: 'Pembelian Terakhir',
    recentlySearched: 'Terakhir Dicari',
    lastPurchased: 'Pembelian Terakhir',
    pageEnd: 'Anda telah sampai bagian akhir halaman ini. Cek kembali di lain waktu.',
    default: 'Untuk mencari produk, ketik merek, molekul atau manufaktur, atau  Anda bisa',
    browse: 'Telusuri katalog produk kami',
    mostPopular: 'Paling Populer',
    campaignBased: 'Paling Populer',
    products: 'Produk',
    manufacturer: 'Manufaktur',
    didYouMean: 'Apakah maksud Anda',
    relatedProducts: 'Produk Terkait',
    seeAllRelated: 'Lihat {{count}} produk terkait',
    seeAll: 'Lihat Semua Produk',
    cartModal,
    filterSort: {
      filterSort: 'Urutkan & Filter',
      filter: 'Filter',
      sort: 'Urutkan',
      sortBy: 'Urutkan',
      loyaltyExtraPoint: 'Extra loyalty point',
      discount: 'Diskon',
      min: 'Min',
      max: 'Max',
      to: 'to',
      mostPurchased: 'Terlaris',
      lowestPrice: 'Harga Terendah',
      discountError: 'diperlukan untuk memasukkan nilai untuk min dan maks',
      highestDiscount: 'Diskon Tertinggi',
      latest: 'Terbaru',
      mostSeen: 'Dilihat Terbanyak',
      reset: 'Reset',
      apply: 'Terapkan',
    },
    ...sectionCard,
  },

  orderCard: {
    orderNumber: 'No. Pesanan',
    invoiceDate: 'tanggal faktur',
    dueDate: 'jatuh tempo',
    paymentDate: 'Tanggal Pembayaran',
    invoices: 'faktur',
    deadline: 'batas waktu',
    paid: 'terbayar',
    paidAmount: 'terbayar',
    outstandingAmount: 'Yang Harus Dibayar',
    partiallyPaid: 'Dibayar Sebagian',
    totalAmount: 'jumlah tagihan',
    totalInvoice: 'jumlah faktur',
    creditNote: 'Nota Kredit Diterapkan',
  },
  orderStatus: {
    partial: 'dibayar sebagian',
    paid: 'Sudah Bayar',
  },
  payments: {
    noAvailableInvoice: 'Tidak ada faktur/tagihan untuk ditampilkan',
    noAvailablePaidInvoice: 'Tidak ada faktur/tagihan lunas untuk ditampilkan',
    payFirst: 'Tidak ada faktur yang sudah lunas, silakan melakukan pembayaran terlebih dahulu. ',
    letsMakeOrder: 'Yuk kita buat pesanan?',
    orderNow: 'PESAN SEKARANG',
    goToUnpaid: 'BELUM LUNAS',
    goToPayment: 'PEMBAYARAN',
    totalAmount: 'Jumlah total',
    invoiceSelection: 'Tagihan Faktur',
    invoiceSelections: 'Tagihan Faktur',
    allSelection: 'Semua',
    selectPaymentOption: 'Pilih opsi pembayaran di bawah ini',
    paymentOption: 'Opsi Pembayaran',
    payAnyAmount: 'Bayar Berapapun',
    totalDueInvoice: 'Faktur jatuh tempo',
    selectedInvoice: 'Faktur Terpilih',
    payWithVoucher: 'Bayar Menggunakan Voucher',
    voucherApplied: 'Diskon {{amount}} berlaku!',
    continue: 'lanjutkan',
    payments: 'pembayaran',
    paymentInformation: 'Informasi Pembayaran',
    areYouSure: 'Apakah Anda yakin?',
    areYouSureSubtitle: 'Anda memiliki pembayaran tertunda. Mohon diproses terlebih dahulu.',
    yes: 'ya',
    no: 'tidak',
    oops: 'Oops!',
    process: 'Proses',
    youHavePendingPayment: 'Anda memiliki pembayaran tertunda. Mohon diproses terlebih dahulu.',
    pendingPayment: 'Pembayaran Tertunda',
    bankInformation: 'Informasi Bank',
    invoiceInformation: 'Informasi Faktur',
    howToPay: 'Bagaimana cara membayar?',
    paymentConfirmation:
      'Untuk konfirmasi pembayaran yang lebih cepat, kami sarankan Anda untuk mengupload bukti pembayaran dari aplikasi mobile banking Anda jika tidak, konfirmasi pembayaran mungkin akan tertunda',
    uploadReceipt: 'Saya Punya Bukti Bayar. Unggah Sekarang',
    uploading: 'Mengunggah {{progress}}, harap tunggu.',
    uploadComplete: 'Berhasil dikirim.',
    cancelPayment: 'Batalkan Pembayaran Ini',
    needHelp: 'Butuh bantuan?',
    contactCustomerService: `Silakan hubungi Layanan Pelanggan kami melalui {{customerServiceNumber}} jika Anda memiliki pertanyaan.`,
    copied: 'disalin ke clipboard',
    vaNumber: 'VA Number',
    slogan: 'Jangan sampai telat pembayaran Anda, yaa',
    payNow: 'bayar sekarang',
    downloadInvoiceSummary: 'unduh semua faktur',
    invoiceDownloadSuccess: {
      title: 'Email terkirim',
      message: 'Mohon periksa email Anda {{email}} untuk mengunduh faktur',
    },
    invoiceDownloadFailed: {
      title: 'Faktur tidak ada',
      message: 'Anda belum membuat pesanan apa pun untuk meminta ringkasan faktur.',
    },
    creditSummary: {
      creditBalance: 'Saldo kredit',
      howToPay: 'Bagaimana cara membayar?',
      availableCredit: 'Tersedia',
      totalUsage: 'Jumlah Pemakaian',
      total: 'Total kredit',
      dueToday: 'Jatuh tempo hari ini',
      dueWeek: 'jatuh tempo akan datang',
      notDue: 'belum jatuh tempo',
    },
    noPaymentsAvailable: 'Belum ada pembayaran',
    seeMore: 'lihat lebih banyak',
    endOfPage: 'Anda telah mencapai akhir halaman',
    showPaidInvoices: 'Lihat Tagihan Terbayar',
    tabNames: {
      unpaid: 'Belum Dibayar',
      due: 'Pembayaran',
      paid: 'Lunas',
      deposits: 'Mutasi',
    },
    tabs: {
      catchMessage: 'Tidak ada tagihan',
      dueDate: {
        catchMessage: 'Tidak ada tagihan',
        dueToday: {
          title: 'Jatuh Tempo Hari Ini',
          today: 'Hari ini',
          desc: 'Nota ini perlu dibayarkan hari ini',
        },
        dueWithinWeek: {
          title: 'Akan Jatuh Tempo',
          desc: 'Lebih baik dibayar secepatnya daripada terlambat',
        },
        notYetDue: {
          title: 'Belum Jatuh Tempo',
          desc: 'Tidak perlu khawatir, ini hanya pengingat',
        },
      },
      paid: {
        notAvailable: 'Information not available',
        message:
          'Kami telah menerima pembayaran Anda dan limit kredit kembali bertambah. Sementara itu kami sedang mengalokasikan faktur yang belum terbayar',
        totalPayments: 'Total Pembayaran',
        remainingPayment: '{{remainingAmount}} belum dialokasikan',
        fullyProcessed: 'Telah dialokasikan',
        partiallyProcessed: '{{allocatedAmount}} dialokasikan',
        unallocatedInfo: 'Sisa dana akan dialokasikan untuk invoice berikutnya',
        refund: 'Nota Kredit',
      },
    },
    voucher: {
      applied: 'Voucher senilai {{amount}} digunakan sebagai pembayaran',
    },
    payAnyAmountInstruction: 'Jumlah yang Anda akan bayar',
    paymentBankInstruction: 'Pilih bank yang akan digunakan untuk pembayaran',
    minimumPayAnyAmount: 'Nominal minimal {{amount}}',
    maximumPayAnyAmount: 'Nominal maksimal {{amount}}',
    selectBankInstruction: 'Pilih bank',
    awaitingPayment: 'Menunggu Pembayaran',
    paymentTransferBefore: 'Harap transfer sebelum',
    billNumber: 'Nomor Bill',
    copy: 'Salin',
  },
  orders: {
    title: 'Aktivitas',
    details: 'Detail Pesanan',
    distributor: 'Distributor',
    search: 'Cari nama produk, distributor, atau nomor faktur',
    dueDate: 'Tanggal Jatuh Tempo',
    poNumber: 'No. Pesanan',
    invoice: 'No. Faktur',
    poNumberCopy: 'Nomor Pesanan disalin',
    invoiceCopy: 'Nomor Faktur disalin',
    invoiceNotAvailable: 'Belum tersedia',
    status: 'Status',
    totalPayment: 'Total Payment',
    itemChanged: 'perubahan barang',
    itemsChanged: 'perubahan barang',
    tour: {
      orderTab: {
        clickHere: 'klik disini',
        title: 'Selamat datang di halaman Pesanan terbaru!',
        description:
          'Kami telah berusaha semaksimal mungkin untuk mendesain ulang halaman pesanan, untuk memastikan bahwa semua informasi yang Anda butuhkan untuk melacak produk yang telah dibeli tersedia.',
        search: 'Pencarian Pesanan',
        searchDesc:
          'Anda juga dapat melihat pesanan berdasarkan tanggal dan distributor menggunakan filter yang kami sediakan.',
        activities: 'Aktivitas',
        activitiesDesc:
          'Ingin melihat pembaruan tentang pesanan Anda? Dengan halaman aktivitas yang baru, Anda dapat mengetahui status terbaru dari pesanan Anda.',
        end: 'Kami berharap Anda puas dengan pengalaman fitur pesanan yang baru ini!',
        endDesc:
          'Jika Anda memiliki pertanyaan atau saran, silahkan hubungi tim Customer Success kami melalui Live Chat',
      },
      orderModal: {
        title: 'Detail Pesanan Baru',
        description:
          'Ini adalah detail pesanan Anda yang baru. Kami telah menambahkan banyak informasi yang akan membantu Anda untuk mengelola pesanan Anda dengan lebih baik.',
        status: 'Status',
        statusDesc: 'Cek riwayat status pesanan Anda dan lacak perkembangannya.',
        documents: 'Dokumen',
        documentsDesc:
          'Sekarang, Anda dapat melihat faktur dan dokumen hanya dengan sekali klik saja. Cukup pilih dokumen yang ingin Anda lihat dan kami akan menampilkan nya untuk Anda',
        products: 'Produk',
        productsDesc:
          'Ingin memeriksa produk-produk di pesanan Anda? Dengan detail pesanan yang baru, kami telah memudahkan Anda untuk mendapatkan informasi yang Anda butuhkan.',
      },
    },
    activityStatus: {
      title: {
        [ActivityStatus.PENDING]: 'Kami Telah Menerima Pesanan Anda',
        [ActivityStatus.PROCESSING]: 'Kami Telah Menerima Pesanan Anda',
        [ActivityStatus.ACCEPTED]: 'Pesanan Telah Diterima',
        [ActivityStatus.ORDER_CHANGED]: 'Ada Perubahan di Pesanan Anda',
        [ActivityStatus.DELAYED]: 'Sementara PBF Sedang Tutup',
        [ActivityStatus.DISPATCHED]: 'Kurir Kami Sedang Menuju ke Anda',
        [ActivityStatus.DELIVERED]: 'Pesanan Telah Sampai',
        [ActivityStatus.FULFILLED]: 'Pesanan Telah Sampai',
        [ActivityStatus.COMPLETED]: 'Pesanan Telah Sampai',
        [ActivityStatus.CANCELLED]: 'Pesanan Anda Dibatalkan',
        [ActivityStatus.RETURNED]: 'Dibatalkan - Retur Semua',
        [ActivityStatus.PREKURSOR_REQUIRED]: 'Dokumen Prekursor Diperlukan',
        [ActivityStatus.PREKURSOR_APPROVED]: 'Dokumen Prekursor Diterima',
        [ActivityStatus.PREKURSOR_CANCELLED]: 'Dokumen Prekursor Dibatalkan',
        [ActivityStatus.PREKURSOR_REJECTED]: 'Dokumen Prekursor Ditolak',
        [ActivityStatus.PREKURSOR_SUBMITTED]: 'Dokumen Prekursor Sedang Diproses',
        [ActivityStatus.PREKURSOR_RESUBMITTED]: 'Dokumen Prekursor Sedang Diproses',
      },
      description: {
        [ActivityStatus.PENDING]:
          'Mohon menunggu, Kami telah menerima pesanan Anda. Ketuk disini untuk informasi lebih lanjut',
        [ActivityStatus.PROCESSING]:
          'Mohon menunggu, Kami telah menerima pesanan Anda. Ketuk disini untuk informasi lebih lanjut',
        [ActivityStatus.ACCEPTED]: 'Pesanan {{ po_number }} telah diterima. Ketuk disini untuk informasi lebih lanjut',
        [ActivityStatus.ORDER_CHANGED]:
          'Pesanan {{ po_number }} ada perubahan. Ketuk disini untuk informasi lebih lanjut',
        [ActivityStatus.DELAYED]:
          'Mohon maaf, pesanan Anda {{ po_number }} sedang tertunda karena PBF sedang tutup sementara. Ketuk disini untuk informasi lebih lanjut',
        [ActivityStatus.DISPATCHED]:
          'Pesanan {{ po_number }} sedang dalam perjalanan. Ketuk disini untuk informasi lebih lanjut',
        [ActivityStatus.DELIVERED]: 'Pesanan {{ po_number }} telah sampai. Ketuk disini untuk informasi lebih lanjut',
        [ActivityStatus.FULFILLED]: 'Pesanan {{ po_number }} telah sampai. Ketuk disini untuk informasi lebih lanjut',
        [ActivityStatus.CANCELLED]: 'Pesanan {{ po_number }} telah dibatalkan. Ketuk disini untuk info lebih lanjut',
        [ActivityStatus.COMPLETED]: 'Pesanan {{ po_number }} telah selesai. Ketuk disini untuk info lebih lanjut',
        [ActivityStatus.RETURNED]:
          'Pesanan {{ po_number }} telah di retur secara full. Ketuk di sini untuk informasi lebih lanjut',
        [ActivityStatus.PREKURSOR_REQUIRED]:
          'Pesanan {{ po_number }} memerlukan dokumen prekursor untuk diunggah. Ketuk di sini untuk informasi lebih lanjut',
        [ActivityStatus.PREKURSOR_APPROVED]: 'Dokumen Prekursor telah diterima. Mohon menunggu proses selanjutnya.',
        [ActivityStatus.PREKURSOR_CANCELLED]:
          'Dokumen Prekursor Anda dibatalkan. Ketuk di sini untuk informasi lebih lanjut.',
        [ActivityStatus.PREKURSOR_REJECTED]:
          'Dokumen Prekursor Anda ditolak. Ketuk di sini untuk informasi lebih lanjut.',
        [ActivityStatus.PREKURSOR_SUBMITTED]:
          'Mohon menunggu, kami telah menerima dokumen prekursor Anda. Ketuk di sini untuk informasi lebih lanjut.',
        [ActivityStatus.PREKURSOR_RESUBMITTED]:
          'Mohon menunggu, kami telah menerima dokumen prekursor Anda. Ketuk di sini untuk informasi lebih lanjut.',
      },
    },
    orderStatus: {
      DEFAULT: '',
      [PharmacyOrderStatus.ALL_ORDERS]: 'Semua Pesanan',
      [PharmacyOrderStatus.WAITING_DOCUMENT]: 'Proses Persetujuan',
      [PharmacyOrderStatus.ORDER_RECEIVED]: 'Dikonfirmasi',
      [PharmacyOrderStatus.PREPARING_GOODS]: 'Sedang diproses',
      [PharmacyOrderStatus.DELAYED]: 'Ditunda',
      [PharmacyOrderStatus.OUT_OF_DELIVERY]: 'Sedang diantar',
      [PharmacyOrderStatus.DELIVERED]: 'Terkirim',
      [PharmacyOrderStatus.CANCELLED]: 'Dibatalkan',
      [PharmacyOrderStatus.COMPLETED]: 'Lengkap',
      [PharmacyOrderStatus.ORDER_CHANGED]: 'Pesanan telah diubah',
      [PharmacyOrderStatus.CANCELLED_FULL_RETURN]: 'Dikembalikan Seluruhnya',
      [PharmacyOrderStatus.FULFILLED]: 'Fulfilled',
      [PharmacyOrderStatus.PAID]: 'Dibayar',
      [PharmacyOrderStatus.UNPAID]: 'Belum Dibayar',
      returned: 'Pesanan dikembalikan',
      deliveredPartialReturn: 'Dikembalikan Sebagian',
    },
    orderPrecursorStatus: {
      all: 'Semua',
      [OrderPrecursorStatus.DOCUMENT_REQUIRED]: 'Dokumen Diperlukan',
      [OrderPrecursorStatus.DOCUMENT_WAITING_APPROVAL]: 'Menunggu Persetujuan Dokumen',
      [OrderPrecursorStatus.DOCUMENT_REJECTED]: 'Dokumen yang Ditolak',
      [OrderPrecursorStatus.DOCUMENT_CANCELLED]: 'Dokumen Dibatalkan',
      [OrderPrecursorStatus.DOCUMENT_APPROVED]: 'Dokumen Disetujui',
    },
    orderHistory: {
      DEFAULT: '',
      [PharmacyOrderStatus.ORDER_RECEIVED]:
        'Kami telah menerima pesanan Anda, dan jangan lupa persiapkan pesanan pembelian anda',
      [PharmacyOrderStatus.PREPARING_GOODS]:
        'Kami mempersiapkan pesanan Anda. Akan kami kabari jika sudah siap dikirim',
      [PharmacyOrderStatus.ORDER_CHANGED]: 'Ada perubahan dalam pesanan Anda',
      [PharmacyOrderStatus.OUT_OF_DELIVERY]: 'Pesanan Anda sedang dalam perjalanan oleh kurir. Harap ditunggu',
      [PharmacyOrderStatus.DELIVERED]: 'Pesanan Anda telah sampai ditempat Anda.',
      [`${PharmacyOrderStatus.DELIVERED}By`]:
        'Pesanan Anda telah sampai ditempat Anda. Diterima oleh {{ received_by }}',
      returned: 'Pesanan Anda dikembalikan',
      cancelled: 'Pesanan Anda telah kami batalkan. Ketuk disini untuk info lebih lanjut',
      date: 'Tanggal',
    },
    orderStatusHistory: {
      DEFAULT: '',
      [OrderStatus.PENDING]: 'Dikonfirmasi',
      [OrderStatus.PROCESSING]: 'Sedang Diproses',
      [OrderStatus.ACCEPTED]: 'Sedang Diproses',
      [OrderStatus.ORDER_CHANGED]: 'Sedang Diproses',
      [OrderStatus.DISPATCHED]: 'Sedang Dalam Pengiriman',
      [OrderStatus.RETURNED]: 'Retur',
      [LogisticDeliveryStatus.DELIVERY_COURIER_ASSIGNED]: 'Kurir Sedang Ditugaskan',
      [LogisticDeliveryStatus.DELIVERY_COURIER_DEPARTED]: 'Kurir Sedang Dalam Perjalanan',
      [LogisticDeliveryStatus.DELIVERY_PICKED_UP]: 'Paket Diambil',
      [LogisticDeliveryStatus.DELIVERY_ONGOING]: 'Sedang Diantar',
      [LogisticDeliveryStatus.DELIVERY_COURIER_ARRIVED]: 'Kurir Sudah Sampai',
      [LogisticDeliveryStatus.DELIVERY_DELIVERED]: 'Terkirim',
      [LogisticDeliveryStatus.DELIVERY_DELAYED]: 'Pengantaran Tertunda',
      [LogisticDeliveryStatus.DELIVERY_FAILED]: 'Paket Gagal Dikirim',
      [LogisticDeliveryStatus.DELIVERY_CANCELED]: 'Paket Gagal Dikirim',
      [OrderStatus.DELIVERED]: 'Terkirim',
      [OrderStatus.CANCELLED]: 'Dibatalkan',
    },
    orderStatusDetailHistory: {
      DEFAULT: '',
      [OrderStatus.PENDING]: 'Pesanan Anda telah dikemas dan akan diserahkan kepada mitra pengiriman',
      [OrderStatus.PROCESSING]: 'Faktur pesanan Anda telah dibuat',
      [OrderStatus.ACCEPTED]: 'Faktur pesanan Anda telah dibuat',
      [OrderStatus.ORDER_CHANGED]: 'Ada Perubahan di Pesanan Anda',
      [OrderStatus.DISPATCHED]: 'Pesanan Anda telah siap dan akan segera diambil oleh kurir',
      [LogisticDeliveryStatus.DELIVERY_COURIER_ASSIGNED]:
        'Pesanan Anda sudah diterima oleh kurir dan akan dikirimkan kepada Anda',
      [LogisticDeliveryStatus.DELIVERY_COURIER_DEPARTED]:
        'Kurir kami telah sampai di pick up point <strong>Gudang</strong>',
      [LogisticDeliveryStatus.DELIVERY_PICKED_UP]: 'Paket Anda berhasil diambil',
      [LogisticDeliveryStatus.DELIVERY_ONGOING]: 'Pesanan Anda sedang dalam perjalanan pengiriman',
      [LogisticDeliveryStatus.DELIVERY_COURIER_ARRIVED]:
        'Kurir kami telah tiba dan menunggu Anda untuk menerima pesanan',
      [LogisticDeliveryStatus.DELIVERY_DELIVERED]: 'Pesanan Anda telah sampai di tempat',
      [`${LogisticDeliveryStatus.DELIVERY_DELIVERED}_received_by`]:
        'Pesanan Anda telah sampai di tempat and diterima oleh <strong>{{received_by}}</strong>',
      [LogisticDeliveryStatus.DELIVERY_DELAYED]: 'Mohon maaf pengiriman hari ini gagal dikirim',
      [LogisticDeliveryStatus.DELIVERY_FAILED]: 'Mohon maaf pengiriman hari ini gagal dikirim',
      [LogisticDeliveryStatus.DELIVERY_CANCELED]: 'Mohon maaf pengiriman hari ini gagal dikirim',
      [`${LogisticDeliveryStatus.DELIVERY_FAILED}_description`]:
        'Mohon maaf pengiriman hari ini gagal dikirim<br/>Alasan: {{description}}',
      [`${LogisticDeliveryStatus.DELIVERY_FAILED}_${LogisticDeliveryStatusDetail.PHARMACY_CLOSED}`]:
        'Mohon maaf pengiriman hari ini gagal dikirim<br/>Alasan: apotek sudah tutup',
      [`${OrderStatus.DELIVERED}_received_by`]:
        'Pesanan Anda telah sampai di tempat and diterima oleh <strong>{{received_by}}</strong>',
      [OrderStatus.CANCELLED]: 'Mohon maaf pengiriman dibatalkan',
    },
    orderNote: {
      DEFAULT: '',
      [OrderStatus.DELIVERED]:
        'Jika ada item yang dikembalikan, kami sedang memproses dan mungkin memerlukan waktu 24 jam untuk mencerminkan perubahan.',
      [LogisticDeliveryStatusDetail.PHARMACY_CLOSED]:
        'Driver sudah tiba di apotek, namun apotek sudah tutup. Driver akan kembali esok hari untuk mengantarkan pesanan.',
    },
    orderStatusDeliveryProof: 'Lihat Bukti Pengiriman',
    tabNames: {
      activity: 'Notifikasi',
      history: 'Riwayat',
    },
    DIRECT_PAYMENT: 'Pembayaran Tunai',
    CREDIT_LINE: 'Pembayaran Kredit',
    paymentMethod: 'Metode Pembayaran',
    purchasedDate: 'Tanggal pemesanan',
    expectedDeliveryDate: 'Perkiraan Tanggal Pengiriman',
    toBeDetermined: 'Akan ditentukan',
    slaPrekursorWarningText: `Perkiraaan tanggal pengiriman akan ditentukan. Silakan unggah dokumen prekursor dibawah ini.`,
    slaUnpaidWarningText: `Perkiraaan tanggal pengiriman akan ditentukan. Harap selesaikan pembayaran`,
    slaUnpaidWarningAction: `Lakukan pembayaran`,
    viewStatus: 'Lihat',
    viewInvoice: 'Lihat',
    invoiceNumber: 'Nomor faktur',
    invoicePhoto: 'Foto faktur',
    taxInvoice: 'Faktur pajak',
    viewTax: 'Lihat',
    deliveryProof: 'Bukti Pengiriman',
    viewProof: 'Lihat',
    note: 'Catatan',
    orderInformation: 'Informasi Pesanan',
    moreDetails: 'Lihat detail',
    priceChanged: 'Harga berubah',
    quantityChanged: 'Kuantitas berubah',
    unitPrice: 'harga satuan',
    quantity: 'Jumlah',
    unit: 'Unit',
    units: 'Unit',
    subTotal: 'Sub-Total',
    discount: 'Potongan harga',
    total: 'Total',
    paymentDetails: 'Informasi pembayaran',
    totalOrders: 'Total pesanan',
    tax: 'Pajak',
    totalAmount: 'Total Pembelian',
    totalAmountHistory: 'Jumlah Pesanan',
    amountAfterTax: 'Jumlah Setelah Pajak',
    paymentInformations: 'Informasi Pembayaran',
    paymentDate: 'Tanggal Pembayaran',
    needAssistanceForThisOrder: 'Butuh bantuan untuk pesanan ini?',
    needAssistanceForReturn: 'Jika ada retur, kami membutuhkan waktu 24 jam untuk proses',
    yesIneedAssistance: 'Ya, saya butuh bantuan',
    needAssistance: 'Butuh bantuan?',
    needAssistanceOrderViaWhatsapp: 'Halo, dapatkah Anda membantu saya dengan Pesanan # {{poNumber}}?',
    detailStatus: 'Detail Status',
    detailChanges: 'Lihat Perubahan',
    orderChanged: 'Pesanan Ada yang Berubah',
    hna: 'Rp/Unit',
    outOfStock: 'Stok Habis',
    orderStatusTitle: 'Status pesanan',
    moreReturnedItems: 'Lihat {{count}} produk lainnya',
    moreOrderItems: 'Masih ada {{count}} barang lagi',
    minimizeOrderItems: 'Minimalkan daftar produk',
    seeMoreDetails: 'lihat lebih detail',
    return: {
      title: 'Produk yang Dikembalikan',
      reason: 'Alasan Pengembalian',
    },
    delivered: {
      title: 'Produk Yang Dikirim',
    },
    loyalty: {
      rewardPass: 'SwipeRx Reward Pass',
      rewardPointAdded: 'Poin Hadiah',
      rewardPointDeducted: 'Poin Dikurangi',
      rewardPointTotal: 'Total Poin Hadiah',
      rewardPending: '(Pending)',
    },
    refund: {
      title: 'Informasi pengembalian dana',
      date: 'Tanggal Nota Kredit',
      value: 'Total Harga Nota Kredit',
      tax: 'Nota Kredit Pajak',
      total: 'Jumlah Nota Kredit',
    },
    creditMemo: {
      title: 'Informasi Memo Kredit',
      no: 'Nota Kredit No.',
      see: 'Lihat',
      document: 'Dokumen Memo Kredit',
      download: 'Unduh',
      documentOnProgress: 'Dokumen nota kredit masih dalam proses.',
    },
    // NOTE: need confirmation
    checkout: 'Check Out',
    prekursorTooltipTour: {
      activityFilter: 'Anda dapat menemukan pesanan dengan dokumen prekursor tertunda di sini',
      activityCard: 'Klik pada kartu ini untuk mengunggah dokumen prekursor',
    },
    alkes: {
      title: 'Alat Kesehatan',
    },
    regular: {
      title: 'Reguler',
    },
    spDocumentNonPrekursor: {
      info: 'Informasi',
      downloadSPSample: 'Unduh Contoh Dokumen SP',
    },
    prekursor: {
      title: {
        required: 'Dokumen Prekursor Diperlukan',
        submitted: 'Menunggu Persetujuan Dokumen Prekursor',
        resubmitted: 'Menunggu Persetujuan Dokumen Prekursor',
        approved: 'Dokumen Prekursor Disetujui',
        rejected: 'Dokumen Prekursor Ditolak',
        cancelled: 'Dokumen Prekursor Dibatalkan',
      },
      add: 'Unggah Documen Prekursor',
      uploadSuccess: {
        title: 'Hore! gambar dokumen prekursor Anda berhasil diunggah',
        info: 'Anda dapat memeriksa status pesanan ini di tab Aktivitas',
        action: 'BAIK',
      },
      uploadFailed: {
        title: 'Ups! Pengunggahan dokumen prekursor Anda gagal',
        info: 'File gagal:',
        action: 'MENCOBA KEMBALI',
      },
      uploadApprovedFailed: {
        title: 'Dokumen sudah di setujui, tidak dapat melakukan update',
        info: 'failed file:',
        action: 'RETRY',
      },
      prekursorTooltipTour: {
        preUpload: 'Klik di sini untuk mengunggah dokumen Prekursor',
        postUpload: 'Ketuk di sini untuk melihat atau mengedit dokumen Prekursor',
        editUpload: 'Ketuk di sini untuk mengedit dokumen Prekursor',
      },
      requiredLabel: 'Perlu Dokumen Prekursor',
      required: 'Perlu Dokumen Prekursor',
      submitted: 'Menunggu Persetujuan Dokumen',
      resubmitted: 'Menunggu Persetujuan Dokumen',
      approved: 'Dokumen Disetujui',
      rejected: 'Dokumen Ditolak',
      cancelled: 'Dokumen Dibatalkan',
      instruction: 'Instruksi',
      downloadSPSample: 'Unduh Contoh Dokumen SP',
      rejected_reason: 'Alasan Ditolak',
      reasons: {
        document_unclear: 'Foto Dokumen Kurang Jelas',
        photo_unclear: 'Foto yang di upload tidak jelas',
        precursor_format_invalid: 'Bukan Format Prekursor',
        address_pbf_invalid: 'Nama dan Alamat PBF Tujuan Tidak Sesuai',
        pharmacy_data_incomplete: 'Dokumen Apotek Tidak Lengkap',
        no_sipnap: 'Tidak Ada SIPNAP',
        sipnap_not_match: 'Data SIPNAP Tidak Sesuai',
        specimen_not_updated: 'Spesimen Tidak Update',
        pharmacy_data_invalid: 'Data Apotek Tidak Sesuai',
        apj_data_invalid: 'Data APJ Tidak Sesuai',
        signature_blank: 'Tanda Tangan APJ Tidak Ada',
        signature_not_match: 'Tanda Tangan APJ Tidak Sesuai',
        signature_dry: 'Tanda Tangan APJ Tidak Basah',
        stamp_blank: 'Cap Apotek Tidak Ada',
        stamp_not_match: 'Cap Apotek Tidak Sesuai',
        stamp_dry: 'Cap Apotek Tidak Basah',
        wrong_core_molecules: 'Zat Aktif tidak sesuai',
        wrong_product_name: 'Nama Produk tidak sesuai',
        wrong_quantity: 'Qty Produk tidak sesuai',
      },
      fileSizeValidationError: 'Ukuran file yang diperbolehkan tidak lebih besar dari {{size}}MB',
      fileTypeValidationError: 'Hanya tipe file gambar yang diperbolehkan',
      generalValidationError: 'Terjadi kesalahan',
      sendSPViaEmail:
        'Kami telah mengirimkan contoh dokumen prekursor ke email anda, silahkan cek email anda yang terdaftar di dalam sistem',
    },
    orderInfoDelivered: 'barang terkirim',
    orderInfoReturned: 'barang dibatalkan',
    letsRateYourOrder: 'Beri penilaian untuk pesananmu yuk',
    howDidYouLikeYourOrder:
      'Bagaimana pengalamanmu secara keseluruhan dalam memesan produk dari <0>{{distributor}}<0>?',
    tapTheStarToRate: 'Ketuk bintang (1 mengecewakan, 5 luar biasa)',
    tellUsMore: 'Beri tahu kami lebih banyak!',
    optional: 'Opsional',
    submit: 'Kirim',
    whatCouldBeImproved: 'Apa yang bisa ditingkatkan?',
    enjoyedLetUsKnow: 'Anda senang? Beritahu kami ...',
    moreProducts: '+{{ n }} produk lagi',
    oneItem: '1 produk',
    writeYourFeedback: 'Tulis Ulasan',
    yourOpinionMatter: 'Pendapatmu penting untuk kami! Luangkan waktu sejenak untuk memberi tahu pendapatmu yuk',
    starRating: {
      terrible: 'Mengecewakan',
      poor: 'Buruk',
      ok: 'Oke',
      good: 'Bagus',
      awesome: 'Luar biasa',
    },
    gladYouEnjoyed: {
      header: 'Kami senang kamu merasa puas dengan layanan kami!',
      text: 'Kami menghargai ulasanmu. Terima kasih telah berbagi pengalaman pesananmu dengan kami!',
    },
    sorryForYourInconvenience: {
      header: 'Ups! Mohon maaf atas ketidaknyamanan kamu',
      text: 'Kami menghargai ulasanmu. Kami berjanji untuk meningkatkan pelayanan kami.',
    },
    done: 'selesai',
  },
  orderConfirmed: {
    title: 'Konfirmasi pesanan',
    hooray: 'Hooray!',
    ordersSuccessfullySubmitted:
      'Pesanan anda telah berhasil dikirim ke sistem kami. Silahkan lihat daftar pesanan di bawah ini.',
    prepareSignedPurchaseOrder: 'Harap Siapkan Surat Pesanan Anda yang telah ditandatangani',
    fulfillOrders:
      'Sistem kami hanya dapat memenuhi pesanan Precursor jika anda telah memiliki Surat Pesanan yang telah dicetak dan ditandatangani. Pastikan untuk mengirimkan Surat Pesanan ke sistem dan menyerahkannya secara fisik kepada kurir kami saat pengiriman.',
    orderSummary: 'Ringkasan pesanan',
    totalOrderAmount: 'Total Jumlah Pesanan',
    eVat: 'PPN',
    totalAfterVat: 'Jumlah Pesanan Total',
    seeDetails: 'Lihat Detail',
    orderNumber: 'No. Pesanan',
    netAmount: 'Jumlah bersih',
    ordered: 'Dipesan pada',
    reserved: 'Dipesan pada',
    amountAfterTax: 'Jumlah setelah Pajak',
    rateYourOrderExperience: 'Bagaimana Anda menilai pengalaman anda saat memesan',
    needHelp: 'Butuh bantuan?',
    ourTeamMoreThanHappyToAssist: 'Customer Success kami akan dengan senang hati melayani Anda.',
    pleaseContact: 'Silakan hubungi tim',
    customerSuccess: 'Customer Success',
    teamViaWhatsApp: `kami melalui {{phoneNumber}}`,
    // NOTE: need confirmation
    checkout: 'Check Out',
    orderedOn: 'Dipesan pada',
    prekursorFlag: 'Dokumen Prekursor Diperlukan',
    alkesFlag: 'Alat Kesehatan',
    regularFlag: 'Reguler',
    prekursorTooltipTour: {
      orderSummary: 'Klik di sini untuk mengunggah dokumen Prekursor',
    },
    spDocumentNonPrekursor: {
      info: 'Informasi',
      downloadSPSample: 'Unduh Contoh Dokumen SP',
    },
    voucher: {
      chooseVoucher: 'Terapkan voucher',
      voucherApplied: 'Voucher {{amount}} berlaku!',
    },
  },
  promotedPage: {
    noPromoted: 'Promo akan segera tersedia. Anda dapat memeriksa lagi nanti.',
    packaging: 'Kemasan',
    quantity: 'Jumlah',
    addProduct: 'Tambah produk',
    addToCart: 'Masukan ke keranjang',
    pbfDeals: 'Penawaran Terbaik',
    showMore: 'Tampilkan lebih',
    greatDeals: 'Lihat lebih penawaran terbaik',
    pageEnd: 'Anda telah sampai bagian akhir halaman ini. Cek kembali di lain waktu.',
    buyNow: 'Beli sekarang',
    noProducts,
    noProductsInArea:
      'Barang yang anda cari tidak tersedia. Silahkan mencari produk lainnya atau bisa menghubungi Tim Customer Success untuk bantuan lebih lanjut.',
    recentlyPurchased: 'Pembelian Terakhir',
    cartModal,
  },
  productPage: {
    removeItemConfirmation: 'Apakah Anda ingin menghapus produk ini dari keranjang Anda?',
    add: 'Beli',
    similarProducts: 'Produk Serupa',
    yes: 'Ya',
    no: 'Tidak',
  },
  accountPage: {
    myAccount: 'Akun saya',
    license: {
      yourActiveLicenses: 'Lisensi Aktif Anda',
      document: 'Dokumen',
      validUntil: 'Berlaku hingga {{valid_until}}',
      valid: 'Sah',
      expiredOn: 'Berakhir pada {{expired_on}}',
      verified: 'Diverifikasi',
      unverified: 'Tidak diverifikasi',
      contactUs: 'Hubungi kami',
      willExpireMsg:
        'Lisensi akan kedaluwarsa pada {{valid_until}}. Harap kirimkan lisensi baru Anda untuk terus menggunakan akun terverifikasi.',
      expiredMsg: 'Lisensi kedaluwarsa. Harap kirimkan lisensi baru Anda untuk terus menggunakan akun terverifikasi.',
      helpUpgrade: 'Halo, saya mau upgrade akun saya, mohon bantuannya.',
      ...pharmacyTierInfo,
    },
    available: {
      creditBalance: 'Sisa kredit',
      availableCredit: 'Kredit Tersedia',
      usedCredit: 'Kredit Terpakai',
      totalCredit: 'Total Kredit',
    },
    balance: {
      outstandingBal: 'Yang Harus Dibayar',
      viewOrders: 'Lihat Pesanan',
      paymentMethod: 'Metode Pembayaran',
      viewDetails: 'Melihat Rincian',
      howToPay: 'Metode Pembayaran',
      bank: 'Bank',
      accountNo: 'Nomor Akun',
      name: 'Nama',
      needHelp: 'Butuh bantuan?',
      helpMore: `Untuk informasi lebih lanjut, silahkan hubungi Tim Customer Success kami melalui {{callCenterNumber}}`,
      noBankDetail: 'Tidak ada detail bank',
    },
    virtualAccount: {
      paymentChannels: 'Jenis Pembayaran',
      setAsDefault: 'Jadikan Utama',
      default: 'Utama',
      copy: 'Salin',
      copied: 'Tersalin',
      learnHowToPay: 'Cara Membayar',
      selectMethod: 'Bank Information',
      instruction: 'Transfer dengan jumlah berapapun melalui Bank pilihan Anda',
      needToDo: `Ini yang kamu butuhkan`,
      transferToAnyOfTheFollowingA: 'Pembayaran',
      transferToAnyOfTheFollowingB: 'pada {{due_date}} kesemua jenis pembayaran:',
      paymentDetails: 'Jumlah sebesar {{value}} akan otomatis terisi di aplikasi bank Anda.',
    },
    help: {
      needHelp: 'Butuh bantuan?',
      customerSuccess: 'Pelanggan Sukses',
      message: 'Silahkan hubungi {{customer_success}} kami via {{number}} jika anda memiliki pertanyaan.',
      message1:
        'Silahkan hubungi Customer Success kami jika ada pertanyaan/kendala lainnya melalui beberapa cara dibawah ini :',
      message2: 'WA Bisnis : https://api.whatsapp.com/message/KC4O52S5TRASN1',
      message3: 'Phone : {{callCenterNumber}}',
      message4: 'Senin - Jumat / 08.00 - 21.00',
      message5: 'Sabtu - Minggu / 08.00-17.00 ',
      message6: 'Libur Nasional / 08.00-17.00',
    },
    swipeApp: {
      swiperx: 'Fitur lainnya',
      news: 'Berita',
      cpd: 'Pendidikan',
    },
    helpSupport: {
      homepage: 'Atur Pengadaan sebagai Halaman Awal',
      howToAddProduct: 'Bagaimana cara menambahkan produk?',
      howToOrder: 'Bagaimana cara membuat pesanan?',
      help: 'Bantuan & Dukungan',
      faq: 'Pertanyaan yang Sering Diajukan',
      policy: 'Kebijakan Privasi',
      contact: 'Hubungi kami',
      terms: 'Syarat dan Ketentuan',
      exit: 'Keluar',
    },
    tour: {
      welcomeTitle: 'Selamat datang di keranjang ada',
      welcomeContent: 'Mari kita cek fitur terbaru',
      detail: 'Rincian Produk',
      detailContent: 'Di sinilah anda dapat menemukan detail akun anda',
      credit: '',
      creditContent: 'Periksa kredit Anda yang tersedia di bagian ini',
      due: 'Apakah Anda ingin mengetahui berapa banyak Anda harus membayar?',
      dueContent: 'Bagian ini memberikan informasi tentang pembayaran tersisa Anda',
      payment: 'Apakah Anda ingin mengetahui bagaimana cara membayar?',
      paymentContent: 'Bagian ini memberikan informasi tentang metode pembayaran',
      swipe: 'Akses Mudah ke SwipeRx',
      swipeContent: 'Klik tautan untuk melanjutkan ke bagian berita atau pendidikan',
      homePage: 'Pengadaan sekarang adalah halaman beranda Anda untuk SwipeRx',
      homePageContent: 'Untuk mengubah pengaturan ini, cukup perbarui item di bawah ini',
      howToAddProductsContent: 'Bagaimana cara menambahkan produk',
      howToCreateOrderContent: 'Bagaimana cara membuat pesanan?',
      exit: '',
      exitContent: 'Keluar dari aplikasi dengan mengklik tombol ini',
    },
    benefits: {
      title: 'Benefit Akun',
      currentAccount: 'Akun sekarang',
      unverified: 'unverified',
      verified: 'verified',
      verifyYourAccountUnlock: 'Verify your account to unlock more benefits',
      typeOfProductsEligible: 'Produk yang bisa dibeli',
      withoutCustomLogo: 'Produk tanpa logo khusus',
      withOTCMarkingLogo: 'Produk dengan logo penandaan OTC',
      greenSymbolWithBlackCircle: 'Simbol hijau dengan lingkaran hitam',
      otcWithoutPrescription: 'Obat yang bebas dijual di pasaran dan bisa dibeli tanpa resep dokter.',
      blueSymbolWithBlackCircle: 'Simbol biru dengan lingkaran hitam',
      otcWithPrescription: 'Obat yang bebas dijual di pasaran dan bisa dibeli tanpa resep dokter.',
      treeSymbol: 'Simbol pohon',
      medicineMadeNatural: 'Obat tradisional atau jamu yang berbahan dasar alami',
      withRedLogo: 'Produk dengan logo merah',
      letterKsymbolRedCircle: 'Simbol huruf ‘K’ dalam lingkaran merah',
      strongDrugsWithPrescription: 'Obat keras yang hanya bisa didapat dengan resep dokter',
      productPrecursor: 'Produk Prekursor',
      multiplePaymentOptions: 'Berbagai Opsi Pembayaran',
      secureAndConvenientPayment: 'Pembayaran yang aman dan nyaman',
      directPaymentOption:
        'Dengan opsi pembayaran langsung, Anda dapat membayar setelah melakukan pemesanan menggunakan VA bank pilihan Anda melalui sistem pembayaran kami yang aman dan terlindungi.',
      unlockCreditLimit: 'Buka Batas Kredit',
      creditLimitBasedEligibility:
        'Kesempatan mendapatkan limit kredit hingga Rp500.000.000 berdasarkan kelayakan Anda.',
      licenseRequirements: 'Persyaratan Lisensi Akun Verified',
      submitDocuments: 'Verifikasi akun Anda dengan mengirimkan dokumen berikut',
      speciment: 'Speciment',
      activeLicense: 'Lisensi Aktif',
      siaDocument: 'SIA Dokumen',
      sipaDocument: 'SIPA Dokumen',
      sipnapDocument: 'SIPNAP Dokumen',
      ownerId: 'KTP Pemilik',
      pharmacistId: 'KTP Apoteker',
      npwpOwnerId: 'NPWP Owner',
      selfieWithID: 'Selfie dengan KTP',
      verifyYourAccount: 'verify your account',
      verifyYourAccountChat1:
        'Hai CS, saya ingin upgrade akun Apotek saya dari unverified menjadi verified, boleh dibantu caranya?',
      verifyYourAccountChat2:
        'Halo CS, saya ingin mengupgrade akun apotek saya untuk membeli produk prekursor, bisa tolong bantu?',
      licenseRequirements2: 'Persyaratan Lisensi',
      licenseTitle2: 'Lisensi Aktif',
    },
    creditLimitUpgradeRequest: {
      cardMessage: 'Ingin meningkatkan batas kredit Anda?\nMinta sekarang.',
      pendingCardMessage: 'Klik untuk melihat status permintaan batas kredit.',
      blockedMessage:
        'Akun Anda diblokir. Ada {{dpdInvoiceCount}} tagihan\nyang belum dibayar hingga {{dpd}} hari.\nSegera lunasi agar bisa belanja lagi.',
      suspendMessage: 'Akun anda ditangguhkan untuk sementara',
    },
  },
  favoritesPage: {
    favorites: 'favorit',
    pageEnd: 'Anda telah sampai bagian akhir halaman ini.',
    empty: 'Anda belum punya produk favorit.',
    removedFromFavoriteProduct: 'Produk telah dihapus dari favorit.',
  },
  productRemindersPage: {
    message: 'Produk ini dihapus secara otomatis dari halaman ini 7 hari dari produk tersedia.',
    noProducts: 'Kamu belum memiliki produk untuk dikabari.',
  },
  productList: {
    pageEnd: 'Anda telah sampai bagian akhir halaman ini.',
    empty: 'Daftar produk mu kosong.',
  },
  productCard: {
    upTo: 'Diskon hingga {{discount}}%',
    addedToFavoriteProduct: 'Produk berhasil ditambahkan ke favorit.',
    removedFromFavoriteProduct: 'Produk telah dihapus dari favorit.',
    prekursorTooltipTour: {
      productCard: 'Produk ini memerlukan dokumen Prekursor',
      productCardTitle: 'Ikon Prekursor',
    },
    maxDailyQtyLimit: 'MAX {{value}}/HARI',
    maxMonthlyQtyLimit: 'MAX {{value}}/BULAN',
  },
  wishlistPage: {
    wishlist: 'Wishlist',
    reminders: 'Kabari Saya',
    comingSoon: 'Segera Hadir',
    favorites: 'Favorit',
  },
  loyalty: {
    today: 'Hari ini',
    noData: 'Tidak ada data',
    point: 'Poin',
    points: 'Poin',
    member: 'Member',
    status: {
      [LoyaltyHistoryStatus.ALL]: 'Semua',
      [LoyaltyHistoryStatus.VERIFIED]: 'Aktif',
      [LoyaltyHistoryStatus.BONUS]: 'Bonus',
      [LoyaltyHistoryStatus.PENDING]: 'Tertunda',
      [LoyaltyHistoryStatus.DEDUCTED]: 'Dikurangi',
      [LoyaltyHistoryStatus.EXPIRED]: 'Kadaluarsa',
      [LoyaltyHistoryStatus.EXPIRING_SOON]: 'Akan Kadaluawarsa',
      [LoyaltyHistoryStatus.REDEEMED]: 'Ditukarkan',
    },
    cardDescription: {
      [LoyaltyHistoryStatus.VERIFIED]: 'Dibeli dari Pesanan',
      [LoyaltyHistoryStatus.BONUS]: 'Dibeli dari Pesanan',
      [LoyaltyHistoryStatus.PENDING]: 'Dibeli dari Pesanan',
      [LoyaltyHistoryStatus.DEDUCTED]: 'Dibeli dari Pesanan',
      [LoyaltyHistoryStatus.EXPIRED]: 'Dibeli dari Pesanan',
      [LoyaltyHistoryStatus.EXPIRING_SOON]: 'Dibeli dari Pesanan',
      [LoyaltyHistoryStatus.REDEEMED]: 'Dibeli',
    },
    loyaltyPointDescriptionTitle: 'Apa itu loyalty point?',
    loyaltyPointDescriptionMore: 'pelajari',
    retry: {
      message: 'Terjadi kesalahan. Mohon coba lagi setelah beberapa menit.',
      button: 'Muat ulang',
    },
    rewards: {
      all: 'Semua Hadiah',
      title: 'Hadiah',
      myRewards: 'Hadiah Saya',
      hotRewards: 'Semua Hadiah',
      redeemNow: 'Tukar sekarang',
      howToUse: 'Cara Penggunaan',
      termsOfUse: 'Syarat Penggunaan',
      redeemedOn: 'Ditukarkan pada',
      redeemDialoag: {
        title: 'Terima Kasih',
        description: 'Anda baru saja menukarkan',
        description2: '',
        close: 'Tutup',
      },
      errorMessage: {
        insufficientPoints: 'Butuh {{points}} poin untuk menukar hadiah',
        dpd: 'Maaf, Anda tidak dapat menukar hadiah karena Anda mempunyai tagihan yang belum dibayar lebih dari {{dpd}} hari (DPD). Segera lunasi tagihan Anda. ',
      },
      filter: {
        pointsRequired: 'Poin yang Dibutuhkan',
        filterByPoints: 'Filter berdasarkan Poin',
        minimumPoints: 'Poin Minimum',
        maximumPoints: 'Poin Maksimum',
        filterByVendor: 'Filter berdasarkan Vendor',
        applyFilter: 'Terapkan Filter',
        vendor: 'Vendor',
        vendorName: 'Nama Vendor',
        search: 'Cari',
      },
      noRedeemData: 'Tidak ada hadiah yang diklaim',
    },
    landing: {
      title: 'Beranda Program Loyalti',
      lastUpdate: 'Pembaharuan Terakhir',
      breakdown: {
        [LoyaltyHistoryStatus.VERIFIED]: 'Poin Aktif',
        [LoyaltyHistoryStatus.PENDING]: 'Poin Tertunda',
        [LoyaltyHistoryStatus.REDEEMED]: 'Poin Ditukarkan',
        [LoyaltyHistoryStatus.DEDUCTED]: 'Poin Dikurangi',
        [LoyaltyHistoryStatus.EXPIRING_SOON]: 'Akan Kadaluarsa',
      },
      seeAllPointsHistory: 'Lihat Mutasi Poin',
      whatDoPointsMean: 'Apa definisi poin di atas?',
      pointMeaning: {
        [LoyaltyHistoryStatus.VERIFIED]: {
          title: 'Poin Aktif',
          description:
            'Poin saat pesanan Anda telah dikirim dan dipenuhi. Poin tertunda akan berubah menjadi poin terverifikasi dalam waktu hingga 7 hari.  Poin ini sudah dapat ditukarkan.',
        },
        [LoyaltyHistoryStatus.PENDING]: {
          title: 'Poin Tertunda',
          description:
            'Poin yang Anda dapatkan setelah Anda berbelanja. Poin ini BELUM dapat ditukar. Poin ini akan tetap pada status tertunda hingga pesanan terverifikasi.',
        },
        [LoyaltyHistoryStatus.REDEEMED]: {
          title: 'Poin Ditukar',
          description:
            'Poin yang dikurangi setelah Anda menukarkan poin untuk hadiah, pengurangan poin sesuai dengan jumlah poin hadiah yang ditukar.',
        },
        [LoyaltyHistoryStatus.BONUS]: {
          title: 'Poin Bonus',
          description:
            'Ini adalah poin didapatkan setiap kali Anda melakukan pemesanan pada produk bertanda khusus atau melalui program berhadiah bonus poin dari kami',
        },
        [LoyaltyHistoryStatus.DEDUCTED]: {
          title: 'Poin Dikurangi',
          description:
            'Poin yang akan dikurangi saat Anda mengembalikan produk. Poin yang dikurangi akan didasarkan pada nilai produk yang dikembalikan.',
        },
        [LoyaltyHistoryStatus.EXPIRING_SOON]: {
          title: 'Akan Kadaluarsa',
          description:
            'Poin yang akan habis masa berlakunya 30 hari dan setelah 30 hari semua poin yang diperoleh tidak dapat lagi ditukarkan. Seluruh poin akan kadaluarsa setelah 6 bulan sejak menjadi poin aktif.',
        },
      },
      seeAllRewards: 'Lihat Semua',
      allRewards: 'Semua Hadiah',
      seeMore: 'Lihat Lebih Banyak',
      benefitAndTiers: 'Tingkatan & Manfaatnya',
      howToEarn: 'Bagaimana cara mendapatkan poin',
      faq: 'Pertanyaan yang Sering Diajukan',
      tnc: 'Syarat dan ketentuan',
      cycle: 'Anda berada di bulan ke {month} dalam siklus ke {cycle}',
      viewMembership: 'Lihat Keanggotaan',
      claimedReward: 'Anda memiliki {{reward}} hadiah yang diklaim',
      pointsDescription: 'Kumpulkan poin untuk setiap pembelanjaan dan tukarkan hadiah di sini!',
      pointsExpiringSoon1: 'Anda memiliki {{points}}',
      pointsExpiringSoon2: 'yang segera kedaluwarsa',
      howToGetPoints: {
        title: 'Cara mendapatkan Poin:',
        orderFromSwiperx: {
          title: 'Belanja Melalui Aplikasi SwipeRx',
          description: 'Dapatkan Rp 50 setiap pembelanjaan Rp 10.000',
          action: 'Belanja sekarang',
        },
        reachHigherTier: {
          title: 'Raih tingkatan tinggi untuk dapatkan Swiper Bonus',
          description: 'Dapatkan bonus poin setiap kali menjadi member Gold, Platinum, & Titanium',
        },
        participateInPromotion: {
          title: 'Ikuti promo bulanan dari kami ',
          description: 'Dapatkan poin tambahan dengan mengikuti promosi bulanan',
        },
        getMoreAdditionalBenefits: {
          title: 'Dapatkan manfaat tambahan lainnya',
          description: 'SEGERA HADIR',
        },
      },
      mostPopularRewards: 'Hadiah paling populer',
      missions: {
        title: 'Misi',
        description: 'Selesaikan misi ini untuk membuka hadiah eksklusif!',
        seeAllLink: 'Lihat Semua',
        seeAllButton: 'LIHAT {{total}} MISI AKTIF',
        viewCompletedButton: 'lihat misi yang telah selesai',
      },
    },
    history: {
      title: 'History',
      pointReceived: 'Poin Didapat',
      pointUsed: 'Poin Dikurangi',
      rollbackMessage: 'Gagal menebus {{rewardName}}. Alasan: {{reason}}. RefId: {{referenceId}}',
      rollbackReturnMessage: 'Rollback return {{memo}}. Alasan: {{reason}}',
    },
    membership: {
      title: 'Keanggotaan Saya',
      tierDescription: 'Poin Anda di siklus ini {{points}} poin.',
      retainLevel: 'Kumpulkan {{points}} poin sebelum {{date}} untuk mempertahankan Keanggotaan {{level}}',
      pointsDescription: 'Kumpulkan poin untuk setiap pembelanjaan dan tukarkan hadiah di sini!',
      breakdown: {
        [LoyaltyHistoryStatus.VERIFIED]: 'Poin Aktif',
        [LoyaltyHistoryStatus.PENDING]: 'Poin Tertunda',
        [LoyaltyHistoryStatus.REDEEMED]: 'Poin Ditukar',
        [LoyaltyHistoryStatus.DEDUCTED]: 'Poin Dikurangi',
        [LoyaltyHistoryStatus.EXPIRING_SOON]: 'Segera Kedaluwarsa',
        [LoyaltyHistoryStatus.BONUS]: 'Poin Bonus',
      },
      redeem: 'Tukarkan',
      pointSummary: 'Ringkasan Poin',
      lastUpdate: 'Pembaharuan Terakhir',
      seeAllPointsHistory: 'Lihat Mutasi Poin',
      pointDefinition: 'Definisi Poin',
      whatIsExpirationDate: 'Apa tanggal kadaluwarsa?',
      expirationDateDetails:
        'Poin yang akan kedaluwarsa dalam 30 hari dan setelah 30 hari semua poin yang diperoleh tidak dapat ditukar. Semua poin akan kedaluwarsa setelah 6 bulan sejak menjadi poin aktif.',
      redeemPointNow: 'Redeem poin sekarang',
      benefitsTitle: 'Manfaat Keanggotaan',
      benefitsBtn: 'PELAJARI',
      benefitsLevel: {
        [LoyaltyTierName.BRONZE]: `Anggota bronze`,
        [LoyaltyTierName.SILVER]: `Anggota silver`,
        [LoyaltyTierName.GOLD]: `Anggota gold`,
        [LoyaltyTierName.PLATINUM]: `Anggota platinum`,
        [LoyaltyTierName.TITANIUM]: `Anggota titanium`,
        [LoyaltyTierName.SPECIALCASHBACK]: `Anggota special_cashback`,
        [LoyaltyTierName.IPECREGISTRANT]: `Anggota ipec_registrant`,
        [LoyaltyTierName.VIP]: `Anggota vip`,
      },
    },
    redeemFailed: {
      DEFAULT: 'Silakan hubungi Dukungan Pelanggan kami',
      OL501: 'Tidak ada kupon tersisa',
      OL502: 'Point anda tidak mencukupi',
      OL503: 'Mohon tunggu, penukaran hadiah anda dalam proses',
      MP13: 'Nomor pelanggan diblokir',
      MP14: 'Nomor tujuan salah',
      MP16: 'Nomor tidak cocok dengan operator',
      MP106: 'Produk untuk sementara tidak berfungsi',
      MP203: 'Nomor terlalu panjang',
      MP206: 'Nomor tujuan telah diblokir',
    },
    redeemDialog: {
      title: 'Harus Diisi',
      description: 'Agar kami dapat memproses penukaran Anda, silahkan isi informasi berikut.',
      privacyPolicyLabel: 'Dengan mengklik kirim, Anda menyetujui',
      privacyPolicyText: 'Peraturan Kebijakan',
      submitText: 'Kirim',
      errorMessage: 'Mohon masukkan data dengan benar',
    },
    redeemStatus: {
      [LoyaltyRewardRedeemDeliveryStatus.ORDERED]: 'Proses',
      [LoyaltyRewardRedeemDeliveryStatus.DISPATCHED]: 'Sedang Diantar',
      [LoyaltyRewardRedeemDeliveryStatus.DELIVERED]: 'Terkirim',
      [LoyaltyRewardRedeemDeliveryStatus.CANCELED]: 'Gagal',
      [LoyaltyRewardRedeemDeliveryStatus.EXPIRED]: 'Kadaluwarsa',
    },
    orderPotentialPoints: 'Pesanan ini berpotensi mendapatkan poin',
    orderIncreasePoints: 'Tingkatkan pesanan anda untuk mendapatkan poin',
    orderEarnPoint: 'Dapatkan',
  },
  loyaltyMission: {
    viewListProduct: 'lihat daftar produk',
    viewDetails: 'selengkapnya',
    promoProduct: '*Promo terbatas hanya untuk produk tertentu',
    tab: {
      [LoyaltyMissionStatus.ACTIVE]: 'AKTIF',
      [LoyaltyMissionStatus.COMPLETED]: 'SELESAI',
      [LoyaltyMissionStatus.EXPIRED]: 'KADALUWARSA',
    },
    rewardPoint: {
      [LoyaltyMissionStatus.ACTIVE]: 'Dapatkan {{value}}',
      [LoyaltyMissionStatus.EXPIRING]: 'Dapatkan {{value}}',
      [LoyaltyMissionStatus.EXPIRED]: 'Dapatkan {{value}}',
      [LoyaltyMissionStatus.COMPLETED]: 'Yeay! Anda berhasil mendapatkan {{value}}',
    },
    rewardGift: {
      [LoyaltyMissionStatus.ACTIVE]: 'Dapatkan {{value}}',
      [LoyaltyMissionStatus.EXPIRING]: 'Dapatkan {{value}}',
      [LoyaltyMissionStatus.EXPIRED]: 'Dapatkan {{value}}',
      [LoyaltyMissionStatus.COMPLETED]: 'Yeay! Anda berhasil mendapatkan {{value}}',
    },
    date: {
      [LoyaltyMissionStatus.ACTIVE]: 'Berakhir: {{value}}',
      [LoyaltyMissionStatus.EXPIRING]: 'Berakhir: {{value}}',
      [LoyaltyMissionStatus.EXPIRED]: 'Kedaluwarsa pada {{value}}',
      [LoyaltyMissionStatus.COMPLETED]: 'Selesai pada {{value}}',
    },
    progress: {
      [LoyaltyMissionActivityTypeCode.GMV]: 'Belanja senilai {{value}}',
      [LoyaltyMissionActivityTypeCode.ACTIVE_DAYS]: 'Aktif belanja di aplikasi selama {{value}} hari',
      [LoyaltyMissionActivityTypeCode.OPEN_APP]: 'Buka aplikasi {{value}} kali',
      [LoyaltyMissionActivityTypeCode.REQUEST_PRODUCT]: 'Permintaan produk {{value}} kali',
      [LoyaltyMissionActivityTypeCode.NOTIFY_ME]: 'Klik tombol ketersediaan produk {{value}} kali',
      [LoyaltyMissionActivityTypeCode.REDEEM_POINTS]: 'Tukarkan {{value}} hadiah',
      [LoyaltyMissionActivityTypeCode.SKU_MIX]: 'Jumlah variasi produk: {{value}}',
    },
    pendingProgress: 'Sejumlah {{value}} masih dalam proses validasi',
    targetReachedLabel: 'Progress',
    targetReachedInfo: {
      title: 'Bagaimana Cara Kerja Misi?',
      description:
        'Pencapaian dihitung berdasarkan transaksi yang telah tervalidasi. Anda akan melihat pencapaian Anda ketika invoice tercetak. ',
    },
    tnc: 'Persyaratan',
    noData: 'Tidak ada misi untuk ditampilkan',
    noActiveTitle: 'Nantikan!',
    noActiveDescription: 'Misi baru akan segera hadir',
    completedDialog: {
      title: 'Selamat!',
      description: 'Anda telah menyelesaikan level {{level}} dari misi {{mission}}! Anda mendapatkan',
      rewardPoints: '{{value}} points',
      viewNextLevel: 'Lihat level berikutnya',
      viewCompletedMission: 'Lihat misi yang selesai',
    },
  },
  loyaltyRegistrationPage: {
    title: 'Selamat datang di SwipeRx Reward Pass!',
    description: `Bergabunglah dengan program loyalitas kami<br/>untuk menikmati berbagai keuntungan saat Anda berbelanja!<br/><br />Perlu informasi lebih lanjut<br />tentang program loyalitas ini?<br />`,
    form: {
      pharmacyLabel: 'Nama apotek',
      pharmacyPlaceholder: 'Nama apotek terdaftar Anda',
      pharmacyInvalid: 'Nama Apotek tidak valid',
      phoneLabel: 'Nomor telepon',
      phonePlaceholder: 'Nomor yang bisa dihubungi',
      phoneInvalid: 'Nomor tidak valid',
      emailLabel: 'Email',
      emailPlaceholder: 'Email yang bisa dihubungi',
      emailInvalid: 'Email tidak valid',
      agree: {
        toSwiperx: 'Saya setuju dengan SwipeRx',
        termsOfUse: 'Syarat dan Ketentuan',
        and: 'dan',
        privacyPolicy: 'Peraturan Kebijakan',
        invalid: 'Silahkan pilih',
      },
    },
    readHere: 'Pelajari lebih lanjut',
    joinNow: 'Bergabung Sekarang',
    alreadyJoined: 'Oops! Sepertinya apotek Anda sudah mengikuti program dengan menggunakan email',
    contactSupport:
      'Jika Anda merasa ada kesalahan, atau ingin mengubah email Anda terkait program loyalitas. Silakan hubungi Pelanggan kami.',
    contactUs: 'Hubungi kami',
    join_now: 'Gabung Sekarang',
    learn_more: 'Selengkapnya',
    next: 'Next',
    skip: 'Skip',
    walkthrough: {
      benefit: {
        title: 'Banyak Keuntungan',
        content: 'Semakin banyak berbelanja, semakin banyak keuntungannya',
      },
      extra_point: {
        title: 'Extra Poin',
        content: 'Dapatkan bonus poin dari promo-promo kami',
      },
    },
  },
  loyaltyBanner: {
    member: 'Member',
    point: 'Poin',
    mission: 'Misi',
    join_now: 'Gabung Sekarang',
    learn_more: 'Selengkapnya',
    next: 'Next',
    skip: 'Skip',
    walkthrough: {
      benefit: {
        title: 'Banyak Keuntungan',
        content: 'Semakin banyak berbelanja, semakin banyak keuntungannya',
      },
      extra_point: {
        title: 'Extra Poin',
        content: 'Dapatkan bonus poin dari promo-promo kami',
      },
    },
    viewRewards: 'Lihat Hadiah',
    viewPlayNow: 'Mainkan Sekarang',
    active_mission: 'Misi Aktif',
    activeMissionsMessage1: 'Ada ',
    activeMissionsMessage2: '{{totalActiveMissions}} misi aktif ',
    activeMissionsMessage3: 'menunggumu!',
    pointsDescription: 'Kumpulkan poin untuk setiap pembelanjaan dan tukarkan hadiah di sini!',
  },
  productRequest: {
    question: 'Tidak menemukan produk yang anda cari?',
    title: 'BERITAHU KAMI DISINI',
    inputLabel: 'Nama Produk',
    inputPlaceholder: 'Ketik nama produk anda...',
    buttonSubmit: 'Beritahu SwipeRx!',
    thankYou: 'Terima kasih atas saran anda!',
    oops: 'Terjadi kesalahan. Mohon coba lagi setelah beberapa menit.',
    lengthLessThan3: 'Nama product harus lebih dari 3 huruf...',
    pageEnd: 'Anda telah sampai bagian akhir halaman ini.',
    empty: `Anda belum pernah merekomendasikan produk apapun.`,
  },
  directPayment: {
    copied: 'Transaction ID tersalin',
    close: 'Tutup',
    billCreateError: 'Gagal membuat tagihan. Coba lagi nanti',
    billCreateErrorRetry: 'Gagal membuat tagihan. Sedang mengulang ... ',
    headerTitle: 'Informasi Pembayaran',
    timerLabel: 'Menunggu pembayaran',
    instructions: 'Kode pembayaran Anda adalah',
    saveQRCode: 'Simpan tampilan layar QR Code ini sebagai referensi',
    totalAmount: 'Jumlah Pembayaran',
    billCode: 'kode bill',
    footerInstructionTitle: 'How to pay',
    footerInstructionDetails: {
      step1: 'Take screenshot of this QR code',
      step2: 'Open your bank’s mobile application',
      step3: 'Upload this QR code into the bank application (QR code will automatically populate total amount)',
      step4: 'Ensure that your bank account has enough funds in order to make a successfull payment',
      step5:
        'Once you have transferred money through your banking app, we will prepare your order for deliver. Please reach out to @SwipeRx_TH with any questions',
    },
    paidBillNotification: {
      title: 'Yay! Pembayaran Anda berhasil!',
      content:
        'Nomor tagihan Anda {{billReference}} telah ditempatkan. Kami sangat senang anda dapat menerima pesanan anda dalam waktu 24 jam.',
      action: 'Periksa pesanan Anda di sini',
    },
    paymentFullNotification: {
      title: 'Yay! Pembayaran Anda berhasil!',
      content: 'Pesanan Anda telah ditempatkan dan akan diproses segera!',
      action: 'Periksa pesanan Anda di sini',
    },
    headerPaymentFullTitle: 'Terima kasih!',
    headerPaymentFullMessage: 'Pesanan Anda akan diproses segera!',
  },
  directPaymentList: {
    amount: 'Jumlah',
    poNumber: 'No. Pesanan',
    checkout: 'Check Out',
    totalAmount: 'Total nilai pesanan',
    voucherApplied: 'Voucher Berlaku',
  },
  directPaymentModal: {
    title: 'Ringkasan pesanan',
    hooray: 'Hooray!',
    ordersSuccessfullySubmitted:
      'Pesanan Anda telah berhasil dikirim ke distributor kami. Silakan lihat daftar pesanan di bawah ini.',
    prepareSignedPurchaseOrder: 'Harap Siapkan Surat Pesanan Anda yang telah ditandatangani',
    fulfillOrders:
      'Distributor kami hanya dapat memenuhi pemesanan Anda jika Anda memiliki pesanan pembelian yang telah dicetak dan ditandatangani. Pastikan untuk memperlihatkan ini kepada distributor kami sebelum pengiriman.',
    eVat: 'PPN',
    ordered: 'Dipesan pada',
    reserved: 'Dipesan pada',
    checkout: 'Check Out',
    netAmount: 'Jumlah bersih',
    amountAfterTax: 'Jumlah Setelah Pajak',
    viewOrder: 'Tampilkan Pesanan',
    orderNumber: 'No. Pesanan',
    orderedOn: 'Dipesan pada',
    voucher: {
      chooseVoucher: 'Pilih Voucher',
      voucherApplied: 'Voucher {{amount}} Berlaku!',
    },
    prekursorTooltipTour: {
      orderSummary: 'Klik di sini untuk mengunggah dokumen Prekursor',
    },
  },
  productDetailPage: {
    availablePromo: 'Promo yang tersedia',
    promoNotAvailable: 'Promo tidak tersedia',
    detail: 'Detail',
    description: 'Deskripsi',
    composition: 'Komposisi',
    coreMolecule: 'Komposisi Inti',
    category: 'Kategori',
    atcClassification: 'Klasifikasi ATC',
    manufacturer: 'Manufaktur',
    shipmentTitle: 'Pengiriman',
    returnTitle: 'Ketentuan Retur',
    nearestExpiryDateTitle: 'Tanggal Kedaluwarsa Terdekat',
    shipmentContent1:
      'Untuk area Jadetabek pengiriman hari yang sama tersedia untuk pesanan yang masuk sebelum pukul 11.00 siang, kecuali hari Minggu dan hari libur nasional, dan pesanan yang masuk setelah pukul 11.00 siang akan diproses di hari berikutnya.Produk akan dikirimkan antara pukul 05.00 sore hingga 10.00 malam setelah PBF memproses pesanan.Untuk area Bogor dan area terdekat di sekitarnya, pengiriman akan dilakukan di hari berikutnya mulai pukul 09.00 pagi untuk pesanan yang masuk sebelum pukul 11.00 siang, kecuali hari Minggu dan hari libur nasional, dan pesanan yang masuk setelah pukul 11 siang akan dikirimkan 2 hari setelah pemesanan.Apabila waktu pengiriman tidak sesuai atau Anda tidak dapat menerima pengiriman tersebut, silahkan hubungi tim Customer Success agar dapat kami jadwalkan ulang pengiriman',
    shipmentContent2: 'Sesuai Kebijakan Perusahaan.',
    addedToFavoriteProduct: 'Produk berhasil ditambahkan ke favorit.',
    removedFromFavoriteProduct: 'Produk telah dihapus dari favorit.',
    addedToCart: 'item telah ditambahkan ke keranjang',
    addedToCartWithMaxQty:
      'Karena Anda telah melakukan pembelian produk ini, Anda hanya dapat membeli {{ maxPurchaseQty }} unit(s) lagi hari ini.',
    seeCart: 'lihat',
    discount: 'Diskon',
    minimum: 'Beli min.',
    totalPrice: 'Total Harga',
    addToCart: 'Tambah Ke Keranjang',
    outOfStock: 'Maaf, produk ini habis terjual',
    maxPurchase: 'Karena permintaan tinggi, Anda hanya dapat memesan\nmax {{value}} unit(s) hari ini.',
    maxPurchaseRemaining:
      'Karena Anda telah melakukan pembelian produk ini, Anda hanya dapat membeli {{ value }} unit(s) lagi hari ini.',
    maxPurchaseReached:
      'Kuantitas maksimum tercapai. Anda hanya dapat memesan hingga {{value}} unit(s) dari produk ini perharinya',
    maxPurchaseMonthlyReached:
      'Kamu akan mencapai batas maksimum bulanan {{value}} untuk produk ini setelah checkout. Jika Anda membutuhkan pembelian setelah checkout, Anda dapat melakukan pembelian kembali di bulan depan atau mohon menghubungi tim Customer Service kami untuk mendapatkan layanan lebih lanjut.',
    maxPurchaseMonthlyThresholdReached:
      'Kamu hampir mencapai batas maksimum bulanan {{value}} untuk produk ini. Jika Anda membutuhkan pembelian lebih dari limit bulanan, sebelum pesanan diproses mohon menghubungi tim Customer Service kami untuk mendapatkan layanan lebih lanjut.',
    notify: 'Kabari saya',
    unnotify: 'Batalkan pengingat',
    similarProducts: 'Produk Serupa',
    loyaltyPointTitle: 'Produk ini dapat memberi Anda',
    loyaltyPointPoints: 'poin!',
    prekursorDocumentRequired: `Produk ini membutuhkan dokumen prekursor.`,
    instruction: `Instruksi`,
    prekursorTooltipTour: {
      productDetail: 'Anda dapat melihat contoh dokumen prekursor di sini',
    },
    maxDailyQtyLimit: 'MAX {{value}}/HARI',
    maxMonthlyQtyLimit: 'MAX {{value}}/BULAN',
    productUnavailable: 'Produk tidak tersedia',
    ...pharmacyTierInfo,
    ...tabsProductDetail,
  },
  homepage: {
    greetings: 'Halo {{pharmacyName}}, selamat datang!',
    categories: 'Kategori',
    recentlyViewed: 'Terakhir Dilihat',
    productInfo: 'Produk\nOriginal',
    competitivePrice: 'Penawaran\nTerbaik',
    sameDayDelivery: 'Pesan\nKapan Saja',
    more: 'MORE',
  },
  bannerListPage: {
    pageTitle: 'Semua Promosi',
  },
  productListingPage: {
    cartModal,
    all: 'all',
    noProducts,
    marketingNoProducts:
      'Produk {{productName}} tidak ada di dalam list {{playlistName}}. Coba untuk cari produk disini',
    search: productListingSearch,
    searchPlaceholder: 'Cari 3000+ produk di sini',
  },
  recentlyViewedPage: {
    title: 'Terakhir Dilihat',
    pageEnd: 'Anda telah sampai bagian akhir halaman ini.',
    search: productListingSearch,
  },
  widget: {
    seeAll: 'Lihat Semua',
    seeAllCategories: 'Lihat Semua Produk+',
  },
  maintenancePage: {
    title: `Dalam Perbaikan`,
    subtitle: `Halaman yang Anda cari sedang dalam perbaikan dan akan kembali normal dengan segera`,
    back: `Kembali`,
    reloadMessage: 'Kami memiliki update baru yang menunggu Anda. Silakan buka kembali aplikasi.',
    close: 'Tutup',
  },
  purchase_order_document_requirement: {
    pendingPrekursorDocument: `Anda memiliki dokumen prekursor yang menunggu untuk diunggah`,
    prekursorTooltipTour: {
      banner: 'Klik di sini untuk mengunggah dokumen Prekursor',
      bannerTitle: 'Dokumen Prekursor Menunggu',
    },
  },

  changePasswordPage: {
    title:
      'Untuk menyelesaikan pendaftaran dan mengakses SwipeRx Belanja, lengkapi kata sandi baru Anda kemudian konfirmasi kata sandi baru tersebut',
    newPass: 'Kata sandi baru',
    enterPass: 'Masukkan kata kunci',
    confirmPass: 'Konfirmasi kata sandi',
    confirmPass2: `Setujui password`,
    submitPass: 'Ganti kata santi',
    successTitle: 'Kata sandi diperbarui!',
    successHeader: 'Kata sandi diperbarui! Kata sandi Anda berhasil diperbarui.',
    successFooter: 'Gunakan kata sandi baru Anda untuk masuk',
    LoginSwipe: 'Masuk SWIPERX',
    oldPass: 'Kata sandi lama',
    passwordReq:
      'Harap masukkan minimal 8 karakter. Kami menyarankan untuk menyertakan setidaknya 1 huruf kapital, 1 huruf kecil, 1 angka, dan 1 karakter khusus untuk membuat kata sandi Anda aman (mis. PTAbc12! #)',
    generalError: 'Silakan ubah kata sandi Anda menggunakan Aplikasi Swipe dengan mengklik "Lupa Kata Sandi."',
    validationEnterPass: 'Silakan masukkan kata sandi Anda',
    validationMinPass: 'Kata sandi minimal harus 8 karakter',
    ValidationPassReq:
      'Minimal 8 karakter kata sandi harus berisi kombinasi huruf besar dan kecil, angka, dan karakter khusus / simbol diperlukan',
    ValidationPassMatch: '"Kata Sandi Baru" dan "Konfirmasi Kata Sandi" harus cocok',
  },
  voucher: {
    vouchers: 'Voucher',
    enterYourVoucherCodeHere: 'Masukkan kode Voucher Anda Di Sini',
    search: 'Cari',
    apply: 'Gunakan',
    applyNow: 'Gunakan Sekarang',
    nextTime: 'Gunakan Nanti',
    limitedOffers: 'Penawaran Terbatas',
    validDate: 'Valid Date',
    free: 'Gratis',
    oops: 'Oops!',
    close: 'Tutup',
    loadMore: 'Muat Lebih Banyak',
    USAGE_LIMIT_ERROR: 'Penggunaan voucher telah melampaui limit',
    LIMIT_PER_CUSTOMER_ERROR: 'Penggunaan voucher telah melampaui limit',
    QUANTITY_RANGE_ERROR: 'Jumlah barang tidak memenuhi syarat voucher',
    AMOUNT_RANGE_ERROR: 'Jumlah pembelanjaan tidak memenuhi syarat voucher',
    SELECTED_PHARMACIES_ERROR: 'Anda tidak dapat menggunakan voucher ini',
    SELECTED_DISTRIBUTORS_ERROR: 'Distributor tidak termasuk dalam syarat voucher',
    SELECTED_DISTRIBUTOR_PRODUCTS_ERROR: 'Produk distributor tidak termasuk dalam syarat voucher',
    SELECTED_PRODUCTS_ERROR: 'Produk tidak termasuk dalam syarat voucher',
    PAYMENT_METHOD_ERROR: 'Metode pembayaran tidakk memenuhi syarat voucher',
    OPEN_LOYALTY_ENROLLED_ERROR: 'Farmasi Anda belum terdaftar ke loyalty program',
    OPEN_LOYALTY_POINT_SUFFICIENT_ERROR: 'Loyalty poin Anda tidak mencukupi',
    OPEN_LOYALTY_VOUCHER_REGISTERED_ERROR: 'Voucher tidak valid',
    INVALID_VOUCHER_ERROR: 'Voucher tidak valid',
    ANY_PENDING_PAYMENT_ERROR: `Voucher tidak dapat digunakan karena terdapat pembayaran tertunda. Mohon selesaikan pembayaran terlebih dahulu.`,
    INVALID_VOUCHER_RULES_ERROR: 'Voucher tidak valid',
    VOUCHER_NOT_FOUND_ERROR: 'Voucher tidak ditemukan',
  },
  unverifiedPharmacy: pharmacyTierInfo,
  snackbar: {
    unverifiedPharmacy: pharmacyTierInfo,
    maxQuantityLimit: {
      maxPurchaseDaily:
        'Maaf! karena permintaan tinggi, Anda hanya dapat memesan hingga {{maxUnit}} unit(s) dari produk ini perharinya.',
      maxPurchaseMonthly:
        'Maaf! Berdasarkan ketentuan internal, Anda hanya dapat memesan hingga {{maxUnit}} unit(s) bulan ini.',
    },
    ok: 'Ok',
  },
  depositDetail: {
    depositInformation: 'Informasi deposit',
    refundInformation: 'Informasi Nota Kredit',
    totalDeposit: 'Total Deposit',
    totalRefund: 'Jumlah Nota Kredit',
    depositAllocatedTo: 'Deposit Dialokasikan ke',
    refundAllocatedTo: 'Nota Kredit Dialokasikan ke',
    full: 'Lunas',
    partial: 'Dialokasi sebagian',
    unallocated: 'Belum dialokasi',
    invoiceNo: 'No. Invoice',
    orderNo: 'No. Order',
    needHelp: 'Butuh Bantuan?',
    contactCustomerService: `Silakan hubungi Layanan Pelanggan kami melalui <0>{{customerServiceNumber}}</0> jika Anda memiliki pertanyaan.`,
    showMore: 'Tampilkan lebih banyak',
    creditMemoInformation: 'Informasi Memo Kredit',
    creditNoteNumber: 'Nota Kredit No.',
    creditMemoDocument: 'Dokumen Memo Kredit',
    creditMemoDocumentInProgress: 'Dokumen nota kredit masih dalam proses.',
    see: 'lihat',
    download: 'unduh',
    creditMemoNumberCopied: 'Nomor nota kredit disalin',
    invoiceNumberCopied: 'Nomor invoice disalin',
    totalAmount: 'Sisa pembayaran',
    outstandingAmount: 'Jumlah tagihan',
    noInvoiceToDisplay: 'Faktur masih belum tersedia',
    goBack: 'Kembali',
    depositWillBeAllocated: 'Dana deposit Anda akan otomatis teralokasi ke faktur berikutnya',
    refundWillBeAllocated: 'Dana pengembalian Anda akan otomatis terkalokasi ke faktur berikutnya',
  },
  similarProductListPage: {
    pageTitle: 'Produk Serupa',
    noProducts,
  },
  addToCartNotif: {
    title: 'Ditambahkan ke keranjang!',
    message: 'Anda telah menambahkan {{productName}} ke backet Anda.',
    action: 'PERGI KE KERANJANG',
  },
  availability: {
    timer: {
      maxPurchaseDaily:
        'Anda telah mencapai jumlah maksimum pembelian untuk produk ini. Anda dapat membeli produk ini lagi besok',
      maxPurchaseMonthly:
        'Kamu akan mencapai batas maksimum bulanan {{value}} untuk produk ini setelah checkout. Jika Anda membutuhkan pembelian setelah checkout, Anda dapat melakukan pembelian kembali di bulan depan atau mohon menghubungi tim Customer Service kami untuk mendapatkan layanan lebih lanjut.',
    },
  },
  noAvailableProducts: {
    title: 'Produk tidak tersedia',
    subtitle: 'Silahkan cari produk lainnya atau hubungi Tim Customer Success untuk bantuan lebih lanjut.',
    fuzzyTitle: 'Ups! Tidak ada hasil untuk {{query}}',
    button: 'CARI PRODUK LAIN',
    relatesTo: 'Pencarian terkait dengan {{query}}',
  },
};

export default lang;
