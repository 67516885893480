/* eslint-disable no-return-await, consistent-return, no-console, @typescript-eslint/explicit-function-return-type */
/* TO DEPRECATE */
import { Action } from 'redux';
import find from 'lodash/find';

import { ISearchItem, ISearchSection } from 'components/common/SearchMenuSuggestions/components/interface';
import { Category } from 'services';
import { recordException } from 'utils/Reporting/Sentry';
import {
  isRecentlySearchDuplicate,
  removeExtraSpaces,
  removeUnnecessarySearchCharacters,
} from 'utils/Search/search.util';
import { trackSearchEvent } from 'utils/Analytics/CausalFoundry';
import { BasePageTracker } from 'utils/Analytics/Segment';
import { IMarketing, MarketingType } from 'services/swipe-rx-pt/resources/marketings/interfaces';
import { SECTION_TITLE_TRANSLATION } from 'components/common/SearchMenuSuggestions/constant';
import SwipeRxProcurement from '../../../utils/Warp/SwipeRxProcurement';
import * as constants from './constants';
import { SearchProducts, MostPopularProduct } from '.';
import { ThunkActionCreator } from '../../../types/thunk';
import { showError, Actions as GlobalActions } from '../Global/actions';
import { v3Client } from '../../../utils/Client/v3';
import { AddProductMaxQuantity } from '../Counter/actions';
import { LOCAL_CAMPAIGN_SEARCH_MARKETING_KEY } from '../CampaignSearchMarketing/constants';

/**
 * Action Types
 */
export interface UpdateRecentAction extends Action {
  type: constants.UPDATE_RECENT;
  recent: SearchProducts;
}

export interface GetAllProductAction extends Action {
  type: constants.GET_ALL_PRODUCTS;
  allProducts: SearchProducts;
}

export interface GetSectionProductsAction extends Action {
  type: constants.GET_SECTION_PRODUCTS;
  allProducts: SearchProducts;
}

export interface StartSearchAction extends Action {
  type: constants.START_SEARCH;
}

export interface UpdateSearchAction extends Action {
  type: constants.UPDATE_SEARCH;
  results: number[] | string[];
  previousQuery: string;
  previousCategories?: string[];
  nextPage: number;
  searchMore: boolean;
  totalProductCount: number;
  shouldReload: boolean;
  respondAt: Date | null;
  respondTime: number;
}

export interface FailSearchAction extends Action {
  type: constants.FAIL_SEARCH;
  error: string;
}

export interface ClearSearchAction extends Action {
  type: constants.CLEAR_SEARCH;
  results: string[];
  previousQuery: string;
  nextPage: number;
  previousResultId: number[] | string[];
}

export interface FetchRecentlySearchedProductsAction extends Action {
  type: constants.FETCH_RECENT_SEARCH;
  recentSearchProducts: ISearchItem[];
}

export interface FetchSearchSuggestionAction extends Action {
  type: constants.FETCH_SEARCH_SUGGESTION;
  query: string;
  suggestions: any[];
  respondAt: Date | null;
  respondTime: number;
}

export interface SetMostPopularProducts extends Action {
  type: constants.SET_MOST_POPULAR_PRODUCTS;
  payload: MostPopularProduct[];
}

export interface SetRecentlyViewedProduct extends Action {
  type: constants.SET_RECENTLY_VIEWED_PRODUCT;
  recentlyViewedProducts: number[];
}

export interface SetCategories extends Action {
  type: constants.SET_CATEGORIES;
  categories: Array<Category>;
}

export interface SetSpecialMarketings extends Action {
  type: constants.SET_SPECIAL_MARKETING_CACHE;
  specialMarketings: { [k in MarketingType]: IMarketing };
}

export interface setPreviousResultId extends Action {
  type: constants.SET_PREVIOUS_RESULT_ID;
  previousResultId: number[] | string[];
}

export type Actions =
  | UpdateRecentAction
  | StartSearchAction
  | UpdateSearchAction
  | FailSearchAction
  | GlobalActions
  | GetAllProductAction
  | GetSectionProductsAction
  | ClearSearchAction
  | FetchSearchSuggestionAction
  | SetMostPopularProducts
  | FetchRecentlySearchedProductsAction
  | SetRecentlyViewedProduct
  | SetCategories
  | SetSpecialMarketings
  | setPreviousResultId
  | AddProductMaxQuantity;

export interface RecentlyProductSearchItem {
  name: string;
}

/**
 * Actions
 */
export const fetchRecent: ThunkActionCreator<Actions> = () => async (dispatch) => {
  try {
    // Fetch recent products
    const recent = await SwipeRxProcurement.Function.run('get-recent-products', { limit: 10 });

    // Dispatch the recent products
    dispatch({ type: constants.UPDATE_RECENT, recent });
  } catch (error) {
    recordException(error, 'fetchRecent');
    console.error(error.message);
    // Dispatch the error
    dispatch({ type: constants.FAIL_SEARCH, error: error.message });
  }
};

export const getAllProducts: ThunkActionCreator<Actions> = (options) => async (dispatch) => {
  try {
    // Fetch all products
    const allProducts = await SwipeRxProcurement.Function.run('get-products', options);
    // Dispatch the recent products
    dispatch({ type: constants.GET_ALL_PRODUCTS, allProducts });

    return allProducts;
  } catch (error) {
    recordException(error, 'getAllProducts', { options });
    console.error(error.message);
    // Dispatch the error
    dispatch({ type: constants.FAIL_SEARCH, error: error.message });
  }
};

export const getSectionProducts: ThunkActionCreator<Actions> = (options) => async (dispatch) => {
  try {
    // Fetch all products
    const allProducts = await SwipeRxProcurement.Function.run('get-products', options);
    // Dispatch the recent products
    dispatch({ type: constants.GET_SECTION_PRODUCTS, allProducts });
  } catch (error) {
    recordException(error, 'getSectionProducts', { options });
    console.error(error.message);
    // Dispatch the error
    dispatch({ type: constants.FAIL_SEARCH, error: error.message });
  }
};

export const searchSuggestion: ThunkActionCreator<Actions> = (query: string) => async (dispatch) => {
  try {
    query = removeUnnecessarySearchCharacters(query);
    if (!query || query.length <= 0) {
      // clear suggestion
      dispatch({
        query: '',
        type: constants.FETCH_SEARCH_SUGGESTION,
        suggestions: [],
        respondAt: null,
        respondTime: 0,
        previousResultId: [],
      });
      return;
    }

    const queryAt = new Date();
    // Dispatch searching
    const result: any = await v3Client.get('products/search/suggestions', {
      query,
    });

    const respondAt = new Date();
    const respondTime = respondAt.getTime() - queryAt.getTime();

    let suggestions: ISearchSection[] = [];
    const addSuggestionSection = (title, products, meta?, fuzzy_search?) =>
      products.length
        ? suggestions.concat({
            id: `${BasePageTracker.GENERAL_SEARCH}-${title}`,
            title,
            products,
            fuzzy_search,
            meta,
          })
        : suggestions;

    const title = !result.data.is_approximate
      ? SECTION_TITLE_TRANSLATION.PRODUCTS
      : SECTION_TITLE_TRANSLATION.DID_YOU_MEAN;
    suggestions = addSuggestionSection(title, result.data.products, result.meta, result.data.is_approximate);

    suggestions = addSuggestionSection(
      'manufacturer',
      result.data.manufacturers.map((name) => ({ id: name, name })),
    );

    // [causal foundry] search tracker
    trackSearchEvent(
      {
        contentBlock: 'e-commerce',
        filter: {},
        page: 1,
        query,
        results_ids: result.data.products.map((item) => `${item.id}`),
      },
      true,
    );

    // Dispatch the results
    dispatch({
      query,
      type: constants.FETCH_SEARCH_SUGGESTION,
      suggestions,
      respondAt,
      respondTime,
    });
  } catch (error) {
    recordException(error, 'searchSuggestion', { text: query });
    console.error(error.message);
    // Dispatch the error
    dispatch({ type: constants.FAIL_SEARCH, error: error.message });
    dispatch(showError(error.message));
  }
};

export const checkProductAvailability = async (productIds) =>
  await SwipeRxProcurement.Function.run('check-product-availability', { product_ids: productIds });

export const fetchMostPopularProducts: ThunkActionCreator<Actions> = () => async (dispatch) => {
  try {
    const result = await v3Client.get('products/popular');

    dispatch({
      type: constants.SET_MOST_POPULAR_PRODUCTS,
      payload: result.data,
    });
  } catch (error) {
    recordException(error, 'fetchMostPopularProducts');
    console.error('@fetchMostPopularProducts', error.message);
    dispatch({ type: constants.FAIL_SEARCH, error: error.message });
    dispatch(showError(error.message));
  }
};

// TODO: SPT-14966
export const fetchRecentlySearchedProducts: ThunkActionCreator<Actions> = () => async (dispatch) => {
  try {
    let recentlySearchedProducts: any[] = [];

    const recentlySearchedProductsFromCache = localStorage.getItem(constants.CACHE_KEY_RECENTLY_SEARCHED_PRODUCTS);

    if (recentlySearchedProductsFromCache) {
      recentlySearchedProducts = JSON.parse(recentlySearchedProductsFromCache).products;
    } else {
      localStorage.setItem(
        constants.CACHE_KEY_RECENTLY_SEARCHED_PRODUCTS,
        JSON.stringify({
          products: recentlySearchedProducts,
        }),
      );
    }

    dispatch({
      type: constants.FETCH_RECENT_SEARCH,
      recentSearchProducts: recentlySearchedProducts,
    });
  } catch (error) {
    recordException(error, 'fetchRecentlySearchedProducts');
    console.error('@fetchMostPopularProducts', error.message);
    dispatch({ type: constants.FAIL_SEARCH, error: error.message });
    dispatch(showError(error.message));
  }
};

export const setRecentlySearchedProducts: ThunkActionCreator<Actions> =
  (selectedProduct: RecentlyProductSearchItem) => (dispatch) => {
    let products: RecentlyProductSearchItem[] = [];
    const searchLimit = 20;
    const trimmedSelectedProduct = { name: removeExtraSpaces(selectedProduct.name) };
    const recentlySearchedProductsFromCache = localStorage.getItem(constants.CACHE_KEY_RECENTLY_SEARCHED_PRODUCTS);

    if (recentlySearchedProductsFromCache) {
      const parsedSearchRecentlyProducts = JSON.parse(recentlySearchedProductsFromCache).products;
      const selectedProductDuplicate = find(parsedSearchRecentlyProducts, ({ name: cachedName }) =>
        isRecentlySearchDuplicate(cachedName, trimmedSelectedProduct.name),
      );
      products = selectedProductDuplicate
        ? [selectedProduct].concat(
            // remove the duplicate values
            parsedSearchRecentlyProducts.filter(
              ({ name: cachedName }) => !isRecentlySearchDuplicate(cachedName, trimmedSelectedProduct.name),
            ),
          )
        : [selectedProduct].concat(parsedSearchRecentlyProducts).slice(0, searchLimit);
    } else {
      products = [selectedProduct];
    }

    localStorage.setItem(
      constants.CACHE_KEY_RECENTLY_SEARCHED_PRODUCTS,
      JSON.stringify({
        products,
      }),
    );
    dispatch(fetchRecentlySearchedProducts());
  };

export const deleteRecentlySearchedKey: ThunkActionCreator<Actions> = (selectedProduct: string) => (dispatch) => {
  let products: RecentlyProductSearchItem[] = [];
  const recentlySearchedProductsFromCache = localStorage.getItem(constants.CACHE_KEY_RECENTLY_SEARCHED_PRODUCTS);
  if (recentlySearchedProductsFromCache) {
    const parsedSearchRecentlyProducts = JSON.parse(recentlySearchedProductsFromCache).products;
    products = parsedSearchRecentlyProducts.filter(({ name: cachedName }) => cachedName !== selectedProduct);
  }

  localStorage.setItem(
    constants.CACHE_KEY_RECENTLY_SEARCHED_PRODUCTS,
    JSON.stringify({
      products,
    }),
  );
  dispatch(fetchRecentlySearchedProducts());
};

export const fetchRecentlyViewedProducts: ThunkActionCreator<Actions> = () => (dispatch) => {
  const recentlyViewedProducts = JSON.parse(localStorage.getItem(constants.CACHE_KEY_RECENTLY_VIEWED_PRODUCTS) || '[]');
  dispatch({
    type: constants.SET_RECENTLY_VIEWED_PRODUCT,
    recentlyViewedProducts,
  });
};

export const setRecentlyViewedProducts: ThunkActionCreator<Actions> = (id: number) => (dispatch) => {
  if (!id) return;

  const cache = JSON.parse(localStorage.getItem(constants.CACHE_KEY_RECENTLY_VIEWED_PRODUCTS) || '[]');
  const recentlyViewedProducts = [id, ...cache.filter((entry) => entry !== id)].slice(0, 100);
  localStorage.setItem(constants.CACHE_KEY_RECENTLY_VIEWED_PRODUCTS, JSON.stringify(recentlyViewedProducts));

  dispatch({
    type: constants.SET_RECENTLY_VIEWED_PRODUCT,
    recentlyViewedProducts,
  });
};

export const fetchAllCategories: ThunkActionCreator<Action> = () => async (dispatch) => {
  const hiddenCategoryConfig = (
    !!process.env.REACT_APP_HIDDEN_CATEGORIES && process.env.REACT_APP_HIDDEN_CATEGORIES.length
      ? process.env.REACT_APP_HIDDEN_CATEGORIES
      : 'pkrt'
  ).split(',');
  const isHiddenCategory = (str: string) =>
    hiddenCategoryConfig.some((ctgry) => str.toLocaleLowerCase().startsWith(ctgry));

  try {
    const result = await v3Client.get<{ data: { id: number; name: string; icon_url?: string }[] }>(
      'product-categories',
    );
    dispatch({
      type: constants.SET_CATEGORIES,
      categories:
        result?.data
          ?.filter(({ name }) => !isHiddenCategory(name))
          .map(({ id, name, icon_url }) => ({ id, name, icon_url })) || [],
    });
  } catch (error) {
    recordException(error, 'fetchAllCategories');
    dispatch({ type: constants.FAIL_SEARCH, error: error.message });
  }
};

export const fetchSpecialMarketing: ThunkActionCreator<Action> = () => async (dispatch) => {
  try {
    const marketingDataResp = await v3Client.get<{ data: IMarketing[] }>(`marketing`, {
      types: [
        MarketingType.REPURCHASE,
        MarketingType.RECENT,
        MarketingType.SIMILAR_PRODUCT,
        MarketingType.CAMPAIGN_SEARCH,
      ],
    });

    if (marketingDataResp && marketingDataResp.data.length > 0) {
      const readObj = marketingDataResp.data.reduce((acc, cur) => {
        acc[cur.type] = cur;
        return acc;
      }, {} as { [k in MarketingType]?: IMarketing });
      dispatch({ type: constants.SET_SPECIAL_MARKETING_CACHE, specialMarketings: readObj });

      window.localStorage.setItem(
        LOCAL_CAMPAIGN_SEARCH_MARKETING_KEY,
        JSON.stringify(readObj[MarketingType.CAMPAIGN_SEARCH]),
      );
      window.dispatchEvent(new Event('local-storage'));
    }
  } catch (error) {
    recordException(error, 'fetchSpecialMarketing');
    dispatch({ type: constants.FAIL_SEARCH, error: error.message });
  }
};

export const setPrevResultId: ThunkActionCreator<Actions> =
  (previousResultId: number[] | string[]) => async (dispatch) => {
    dispatch({
      type: constants.SET_PREVIOUS_RESULT_ID,
      previousResultId,
    });
  };
